.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* custom css */
/* Slider.css */
.slider-container {
	padding: 20px; /* Add desired padding between slides */
  }
  .smartech-mobile-slider .slick-slide{
    padding-top: 0;
    padding-bottom: 0;
  }
  .slider-item {
	text-align: center;
  }
  .slick-slider{width:100%}

  .slick-slide {
	padding: 10px; /* Add desired padding to the slide elements */
  }

.features-inner-slider .slick-prev, 
.features-inner-slider .slick-next,
.responsible-card-slides .slick-prev,
.responsible-card-slides .slick-next{
	position: absolute;
    top: 50%;
    display: block;
    width: 20px;
    height: 20px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
	color: var(--primary-color);
    border: none;
    outline: none;
    background: transparent;
}

.features-inner-slider .slick-prev:before,
.responsible-card-slides .slick-prev::before {
	content:"\f053";
	color: var(--primary-color);
	font-family: FontAwesome;
}

.features-inner-slider .slick-next::before,
.responsible-card-slides .slick-next::before {
	content:"\f054";
	color: var(--primary-color);
	font-family: FontAwesome;
}


/* please note page css */

.note-information {
  height: 100vh;
position: relative;
  background-size: cover;
  background-position: center;
background-repeat: no-repeat;
background-image: url(../public/images/please-note-bg.jpg);
}

.note-information::before {
content: '';
top: 0;
left: 0;
width: 100%;
height: 100%;
position: absolute;
background-color: rgba(47, 47, 47, 0.80);
}


.brand-logo {
padding-top: 60px;
position: relative;
padding-left: 108px;
}

.warning-text-wrapper {
padding-top: 100px;
position: relative;
padding-left: 196px;
}


.info-text h1{
color: #fff;
margin: 30px 0;
font-family: 'DunbarTall';
}

.info-text p{
font-size: 20px;
margin-bottom: 30px;
line-height: 31px;
color: #fff;
}

.yellow-text {
color: #D2AE5F;
}

.note-list li {
display: flex;
align-items: center;
margin-bottom: 18px;
}

.note-list li span {
font-size: 18px;
  display: inline-block;
}

.note-list li:last-child {
margin-bottom: 0;
}

.note-list li img {
margin-right: 10px;
}

.info-btn-wrapper {
margin-top: 50px;
}

.info-btn {	
padding: 12px 0;
}
.info-btn-wrapper span,
.info-btn span {
color: #fff;
font-size: 12px;
padding: 12px 25px;
letter-spacing: 1.68px;
text-transform: uppercase;
border: 1px solid #D2AE5F;
transition: all 500ms ease-in-out 0s;
-o-transition: all 500ms ease-in-out 0s;
-ms-transition: all 500ms ease-in-out 0s;
-moz-transition: all 500ms ease-in-out 0s;
-webkit-transition: all 500ms ease-in-out 0s;
}
.info-btn-wrapper span:hover{
  background-color: #D2AE5F;
  color: var(--bs-white);
}

.info-btn img{
margin-left: 30px;
}

.disclimer-text {
font-size: 14px;
margin-bottom: 7px;
}


@media (max-width: 1399px) {
  .brand-logo {
      padding-top: 40px;
  }

  .info-text h1 {
      font-size: 32px;
      margin: 25px 0;
  }

  .info-icon img {
      width: 42px;
  }

  .info-text p {
      font-size: 18px;
      margin-bottom: 30px;
      line-height: 31px;
  }

  .warning-text-wrapper {
      padding-top: 52px;
      padding-left: 160px;
  }

  .note-list li span {
      font-size: 16px;
      display: inline-block;
  }

  .info-btn-wrapper {
      margin-top: 29px;
  }

  .info-text p {
      font-size: 17px;
      margin-bottom: 21px;
  }

  .note-list li img {
      width: 19px;
  }

  .note-list li {
      margin-bottom: 12px;
  }
}
@media (max-width: 1199px){
  .sustainability-slider-body p {
    min-height: 100px;
  }
}
@media (max-width: 992px) {
  body{
    height: 100vh;
  }
  #root, .note-information, #root section{ 
    height: auto;
  }
  .brand-logo {
      padding-left: 40px;
  }

  .warning-text-wrapper { 
      padding-left: 80px;
  }
  .note-information {
      padding-bottom: 70px;
  }
  .sustainability-slider-body p {
    min-height: 130px;
  }
}


@media (max-width: 767px) {
.server-umdermaintenance {
      background-image: url(../public/images/mob-view.jpg);
  }


  .warning-text-wrapper {
      padding-left: 0;
  }

  
  .info-text p {
      font-size: 18px;
      margin-bottom: 25px;
  }

  
  .info-icon img {
      width: 45px;
  }

  .info-text h1 {
      margin: 25px 0;
  }

  .info-btn-wrapper {
      margin-top: 40px;
  }
  .slider-item .smart-slide-img{
    max-width: 370px;
  }
}

@media (max-width: 575px) {
  .brand-logo  {
      padding-left: 0;
      text-align: center;
  }

  .info-text p {
      font-size: 18px;
  }

  .note-list li span {
      font-size: 17px;
  }

  
}

@media (max-width: 480px) {
  .info-text h1 {
      margin: 20px 0;
  }
}

@media (max-width: 380px) {
  .info-text p {
      font-size: 17px;
  }

  .brand-logo img {
      width: 110px;
  }
}

@media (max-width: 320px) {
  .info-icon img {
      width: 37px;
  }

  .info-text p {
      font-size: 16px;
  }

  .note-list li span {
      font-size: 14px;
  }

  .info-btn-wrapper {
      margin-top: 36px;
  }

  .info-text p {
      margin-bottom: 24px;
  }

  .warning-text-wrapper {
      padding-top: 45px;
  }
}
.note-list li span{
  color: #fff;
}

.world-experience {
  padding-top: 50px;
}
.our-board {
  padding-top: 50px;
}
.our-management {
  padding-top: 50px;
}
.find-us {
  padding-top: 50px;
}


.page-title-logo{
  max-width: 630px;
}
.page-title-logo::before,
.page-title-logo::after{
  content: "";
  position: absolute;
  left: 0;
  height: 1px;
  top: 0;
  bottom: 0;
  margin: auto;
  background-color: var(--dark-yellow-color);
  width: calc(50% - 45px);
}
.page-title-logo::after{
  left: initial;
  right: 0;
}
.logo-circle img{
  width: 55px;
}

.sustainability .slick-prev{
  left: -19px !important;
}

.sustainability .slick-prev:before, .sustainability .slick-next:before {
  color: #333333;
}


/* notepage main */
body, html{
	height: 100%;
}
body {
	margin: 0 auto;
	padding: 0;
	font-family: 'Public Sans', sans-serif;
}

ul {
	padding-left: 0;
}

li {
	list-style-type: none;
}

@font-face {
	font-family: 'DunbarTall';
	src: url(../public/fonts/dunbar-tall/DunbarTall-Regular.woff2) format('woff2');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

.server-umdermaintenance {
    height: 100vh;
    background-size: cover;
    background-position: center;
	background-repeat: no-repeat;
	background-image: url(../public/images/under-maintenance.jpg);
}

/* please note page css */

.atmosphere-note {
    height: 100vh;
	background-color: #333333;
    background-size: cover;
    background-position: center;
	background-repeat: no-repeat;
	background-image: url(../public/images/please-note-bg.jpg);
	position: relative;
	overflow: hidden;
}
.atmosphere-note::before{
	content: "";
	position: absolute;
	bottom: 0;
	top: 0;
	right: 0;
	left: 0;
	background-color: rgba(47, 47, 47, 0.8);
}

.brand-logo {
	padding-top: 60px;
	position: relative;
	padding-left: 108px;
}

.warning-text-wrapper {
	padding-top: 100px;
	position: relative;
	padding-left: 196px;
}


.top-text h1{
	color: #fff;
	margin: 30px 0;
	font-family: 'DunbarTall';
	padding-left: 25px;
}
.top-text{
	display: flex;
	align-items: center;
}

.info-text p{
	font-size: 20px;
	color: #fff;
	margin-bottom: 30px;
	line-height: 31px;
}

.yellow-text {
	color: #D2AE5F;
}

.note-list li {
	display: flex;
	align-items: center;
	margin-bottom: 18px;
}

.note-list li span {
	font-size: 18px;
	color: #ffff;
    display: inline-block;
}

.note-list li:last-child {
	margin-bottom: 0;
}

.note-list li img {
	margin-right: 10px;
}

.info-btn-wrapper {
	margin-top: 50px;
}

.info-btn {	
	padding: 12px 0;
}

.info-btn span {
	color: #fff;
	font-size: 12px;
	padding: 12px 25px;
	letter-spacing: 1.68px;
	text-transform: uppercase;
	border: 1px solid #D2AE5F;
}

.info-btn img{
	margin-left: 30px;
}

.disclimer-text {
	font-size: 14px;
	margin-bottom: 7px;
}


/* Notepage Responsive */

@media (max-width: 1399px) {
  .brand-logo {
      padding-top: 40px;
  }

  .brand-logo img {
      width: 130px;
  }

  .info-text h1 {
      font-size: 32px;
      margin: 25px 0;
  }

  .info-icon img {
      width: 42px;
  }

  .info-text p {
      font-size: 18px;
      margin-bottom: 30px;
      line-height: 31px;
  }

  .warning-text-wrapper {
      padding-top: 52px;
      padding-left: 160px;
  }

  .note-list li span {
      font-size: 16px;
      display: inline-block;
  }

  .info-btn-wrapper {
      margin-top: 29px;
  }

  .info-text p {
      font-size: 17px;
      margin-bottom: 21px;
  }

  .note-list li img {
      width: 19px;
  }

  .note-list li {
      margin-bottom: 12px;
  }
}

@media (max-width: 1199px) {
  
  .brand-logo img {
      width: 99px;
  }

  .info-icon img {
      width: 32px;
  }

  .info-text h1 {
      font-size: 28px;
      margin: 18px 0;
      line-height: 21px;
  }

  .brand-logo {
      padding-left: 61px;
  }

  .warning-text-wrapper {
      padding-top: 48px;
      padding-left: 116px;
  }

  .info-text p {
      font-size: 17px;
      margin-bottom: 18px;
  }

  .note-list li span {
      font-size: 15px;
      display: inline-block;
  }

  .info-btn-wrapper {
      margin-top: 20px;
  }
  .atmosphere-note {
      height: auto;
      min-height: 100%;
  }
  .note-information{
      padding-bottom: 30px;
  }
}

@media (max-width: 992px) {
  
  .brand-logo {
      padding-left: 40px;
  }


  .warning-text-wrapper { 
      padding-left: 80px;
  }
}

@media (max-width: 767px) {
.server-umdermaintenance {
      background-image: url(../public/images/mob-view.jpg);
  }

  .warning-text-wrapper {
      padding-left: 0;
  }

  
  .info-text p {
      font-size: 18px;
      margin-bottom: 25px;
  }

  
  .info-icon img {
      width: 45px;
  }

  .info-text h1 {
      margin: 25px 0;
  }

  .info-btn-wrapper {
      margin-top: 40px;
  }
  .warning-text-wrapper{
      padding: 0 10px;
  }
}

@media (max-width: 575px) {
  .brand-logo  {
      padding-left: 0;
      text-align: center;
  }

  .info-text p {
      font-size: 18px;
  }

  .note-list li span {
      font-size: 17px;
  }

}

@media (max-width: 480px) {
  .info-text h1 {
      margin: 20px 0;
  }
}

@media (max-width: 380px) {
  .info-text p {
      font-size: 17px;
  }

  .brand-logo img {
      width: 110px;
  }
}

@media (max-width: 320px) {
  .info-icon img {
      width: 37px;
  }

  .info-text p {
      font-size: 16px;
  }

  .note-list li span {
      font-size: 14px;
  }

  .info-btn-wrapper {
      margin-top: 36px;
  }

  .info-text p {
      margin-bottom: 24px;
  }

  .warning-text-wrapper {
      padding-top: 45px;
  }
}
.explore-now .slider-btn::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: inherit;
  border-radius: 50%;
  animation: circle-ripple 2s ease-in-out infinite 0s;
  background-color: rgba(255,255,255,0.5);
}
@keyframes circle-ripple {
    0% {
      opacity: 1;
      transform: scale(0);
    }
    100% {
      opacity: 0;
      transform: scale(1.6);
    }
  }


  .first-visual{
z-index:1;
  }