@media (max-width: 1780px){
    .wide-container{
        max-width: 1470px;
    }
    .front-window{
        transform: scale(0.65) translateX(960px);
        -o-transform: scale(0.65) translateX(960px);
        -ms-transform: scale(0.65) translateX(960px);
        -moz-transform: scale(0.65) translateX(960px);
        -webkit-transform: scale(0.65) translateX(960px);
    }
    .main-banner .social-media{
        left: 30px;
    }
    .slider-card {
        padding-left: 90px;
      }
}
@media (max-width: 1499px) {
    .wide-container{
        max-width: 1170px;
    }
    .atamosphere-image-slide .active-slide .window-visual {
        max-width: 350px;
    }
    .atamosphere-image-slide .demo-slide .window-visual {
        max-width: 350px;
    }
    .atamosphere-image-slide .window-visual {
        max-width: 270px;
    }
    .front-window {
        transform: scale(0.65) translateX(840px);
        -o-transform: scale(0.65) translateX(840px);
        -ms-transform: scale(0.65) translateX(840px);
        -moz-transform: scale(0.65) translateX(840px);
        -webkit-transform: scale(0.65) translateX(840px);
    }
    .atamosphere-left-text{
        max-width: 325px;
    }
    .slider-card .slider-text p{
        padding-right: 15px;
    }
    .atamosphere-image-slide {
        width: calc(100% - 325px);
    }
    .slider-card {
        padding-left: 50px;
    }
    .main-slider .slider-progress{
        width: calc(100% - 65px);
    }
    .main-slider .slider-content {
        padding: 160px 0 75px;
    }
    .main-slider .slider-progress{
        padding: 0 0 0 30px;
    }
    .slider-progress #slider-counter{
        font-size: 50px;
        width: 65px;
    }
    .main-banner .social-media {
        left: 20px;
    } 
}

@media (max-width: 1399px) {  
    .sustainability-slider {
        width: 840px;
        margin-top: -155px;
    }
    .sustainability-card {
        padding: 20px;
    }
    .yellow-next {
        right: -26px;
    }
    .responsible-card-slides {
        margin-top: -275px;
    }
    .sustainability-body p {
        padding-right: 50px;
    }
    .villa-inner-visual ul{
        max-width: 515px;
    }
    .villa-inner-visual ul li:nth-child(3) .label-bottom-lines {
        height: 193px;
    }
    .sustainability-point-card{
        padding: 25px 30px;
    }
    .social-links li:not(:last-child) {
        padding-right: 25px;
    }
    .main-banner .welcome-text{
        top: 360px;
    }
    .main-banner .explore-now {
        bottom: 23% !important;
    }
}

@media (max-width: 1200px) { 
   
    .window-wrapper .window-border {
        right: -4px;
        bottom: -20px;
        width: 254px;
        height: 362px;
    }

    .window-wrapper .blur-window {
        width: 265px;
        height: 388px;
    }

    .slider-card .slider-visual {
        width: 254px;
    }

    .slider-visual img {
        width: 254px;
    }


    .window-visual {
        width: 314px;
    }
    .features-window .features-border {
        height: 417px;
        width: 318px;
    }

    .intuitive-luxury-image img {
        width: 100%;
    }

    .brand-heritage-image {
        padding-left: 0;
    }

    .sustainability-visual img {
        width: 100%;
    }

    .sustainability-slider {
        width: 800px;
    }


    .intuitive-visual img {
        width: 100%;
    }

    .intuitive-card-body p {
        padding-right: 0;
    }

    .intuitive-card-body span {
        font-size: 18px;
    }

    .intuitive-visual {
        margin-top: 60px;
    }

    .responsive-md-hide {
        display: none !important;
    }

    
    
   .slide-arrows {
        display: none;
   }
   .our-properites {
        padding: 232px 0 90px;
    }
    .iphone-frame img{
        width: initial;
    }
}

@media (max-width: 1199px) {
    h1{
        font-size: 42px;
    }
    h2{
        font-size: 29px;
    }
    .h3, h3{
        font-size: 18px;
    }
    body{
        overflow-x: hidden;
    }
    .sustainability-body p {
        padding-right: 0px;
    }
    .responsible-card-slides {
        margin-top: -145px;
    }
    .sustainability, .intuitive-tech {
        padding: 60px 0;
    }
    .iphone-frame{
        max-width: 335px;
    }
    .iphone-frame img{
        width: calc(100% + 4px);
    }
    .app-ui-design img{
        margin-left: 0;
    }
    .iphone-ui{
        max-width: 322px;
        padding-left: 3px !important;
    }
    .right-side-tabs {
        padding-left: 200px;
    }
    .intuitive-living {
        padding: 50px 0 100px;
      }
    .intuitive-living-accordion .accordion-button{
        font-size: 18px;
        padding: 17px 0;
    }
    .curiosity-form .form-control{
        padding: 15px 20px;
    }
    .welcome-our-curiosity {
        padding: 65px 0;
      }
    .form-submit .btn.theme-btn {
        padding: 14px 70px;
    }
    footer .newsletter {
        margin: 40px 0 60px;
        padding-bottom: 35px;
      }
      .copyright {
        margin: 50px 0 0;
        padding: 20px 0;
      }
      #scrolltop{
        bottom: 65px;
      }
      .blog-box{
        padding: 20px 15px;
      }
      .search-box input{
        height: 50px;
        padding-right: 50px;
      }
      .blog-post .blog-post-body h2{
        margin: 0 0 15px 0;
      }
      .blog-detail li i{
        font-size: 25px;
      }
      .blog-detail li:not(:last-child){
        padding-right: 30px;
      }
      .blog-post .blog-post-body{
        padding: 20px 0 50px;
      }
      .pagination a{
        width: 50px;
        height: 50px;
      }
      .blog{
        padding: 50px 0;
      }
      .sticky a.navbar-brand img{
        width: 190px;
      }
      .welcome-our-curiosity .heading h2{
        max-width: 590px;
    }
    .customer-relation {
        padding: 80px 0;
    }
    .privilege-accordion .accordion-item button{
        font-size: 19px;
    }
    .privilege-accordion .accordion-item button span{
        padding-right: 50px;
    }
    .privilege-accordion .accordion-item .accordion-body{
        max-width: calc(100% - 50px);
    }
    .atmosphere-window{
        width: 425px;
        height: 585px;
    }
    .atmosphere-window-box .atmosphere-window-border{
        right: 0;
        width: 415px;
        height: 586px;
    }
    .tie-up .form .form-input{
        height: 50px;
    }
    .contact-type-card.phone-type-card{
        border-right: 0;
    }
    .contact-type-card{
        padding: 30px 0;
        max-width: 100%;
    }
    .feature-wrapper .nav-pills .nav-item .nav-link{
        flex-wrap: wrap;
        font-size: 18px;
    }
    .feature-wrapper .nav-pills .nav-item .nav-link span{
        width: 100%;
    }
    .features-inner-slider .feature-card{
        padding: 45px 13px 15px;
    }
    .features-inner-slider .feature-body{
        margin-top: 20px;
    }
    .feature-body span{
        margin-bottom: 10px;
    }
    .feature-body p{
        margin: 0 auto 18px;
    }
    .features-inner-slider{
        margin-top: -103px;
    }
    .feature-inner-wrapper{
        padding: 75px 0 0;
    }
    .feature-wrapper .nav-pills .nav-item .nav-link span{
        margin-top: 15px;
        display: block;
    }
    .intutive-luxury{
        padding: 125px 0 85px;
    }
    .banner-inner{
        padding: 200px 0 100px;
    }
    .wide-container {
        max-width: 960px;
    }
    .main-banner .social-media{
        left: 0;
    }
    .atamosphere-left-text {
        max-width: 290px;
    }
    .atamosphere-image-slide {
        width: calc(100% - 290px);
    }
    .atamosphere-image-slide .window-visual {
        max-width: 210px;
    }
    .atamosphere-image-slide .active-slide .window-visual {
        max-width: 320px;
    }
    .main-slider button {
        width: 40px;
        height: 40px;
    }
    .main-slider .slider-progress{
        padding: 0 0 0 15px;
    }
    .slider-progress #slider-counter{
        font-size: 50px;
    }
    .slider-progress .progress{
        width: calc(100% - 80px);
    }
    .main-slider .slider-content {
        padding: 120px 0 45px;
    }
    .atamosphere-left-text .btn{
        font-size: 14px;
        padding: 12px 20px;
    }
    .slider-card {
        padding-left: 30px;
    }
    .atamosphere-image-slide .active-slide .window-visual {
        max-width: 290px;
    }
    .atamosphere-image-slide .demo-slide .window-visual {
        max-width: 290px;
      }
    .front-window {
        transform: scale(0.6) translateX(745px);
        -o-transform: scale(0.6) translateX(745px);
        -ms-transform: scale(0.6) translateX(745px);
        -moz-transform: scale(0.6) translateX(745px);
        -webkit-transform: scale(0.6) translateX(745px);
    }
    .contactus-form-description {
        padding-left: 35px;
    }
    .partners-contact-form{
        padding-right: 35px;
    }
    .tie-up .form textarea{
        max-height: 105px;
    }
    .tie-up {
        padding: 0px 0 30px;
    }
    .contact-type-block{
        padding: 30px 0;
    }
    .contact-type-block li{
        padding: 0 20px;
        min-width: 200px;
    }
    .contact-type .social-media a:not(:last-child) {
        margin-right: 20px;
    }
    .contact-type-block li:nth-child(4) {
        padding-left: 30px;
        min-width: 217px;
    }
    .contact-type .social-media a i {
        font-size: 23px;
    }
    .map {
        padding: 0px 0 40px;
    }
    .our-properites {
        padding: 100px 0 90px;
    }
    .properties-slider-inner{
        min-height: 330px;
    }
    .single-property.property-active-slide{
        width: 530px;
    }
    .property-caption{
        padding: 0 30px 25px;
    }
    .single-property.property-active-slide .property-caption h2{
        font-size: 35px;
    }
    .single-property{
        width: 325px;
        transform: translateX(550px);
        -o-transform: translateX(550px);
        -ms-transform: translateX(550px);
        -moz-transform: translateX(550px);
        -webkit-transform: translateX(550px);
    }
    .property-img {
        clip: rect(43px, 277px, 260px, 45px);
    }
    .single-property + .single-property + .single-property {
        transform: translateX(899px);
        -o-transform: translateX(899px);
        -ms-transform: translateX(899px);
        -moz-transform: translateX(899px);
        -webkit-transform: translateX(899px);
    }
    .media-gallery-slides {
        padding: 70px 0 50px; 
    }
    .experience-img-wrap {
        min-height: 30px;
    }
    .experice-tab-title{
        min-height: 45px;
    }
    .world-experience-tab ul li button.active::before {
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        border-top: 15px solid var(--primary-color);
        bottom: -15px;
    }
    .founder-info {
        padding: 70px 0;
    }
    .about-founder-text p{
        margin: 15px 0 0;
    }
    .management {
        padding: 0 0 50px 0;
    }
    .sustainability-description {
        padding-right: 0px;
    }
    .sustainability-point-card {
        padding: 20px 25px;
    }
    .sustainability-point-card .point-card-icon img {
        height: 40px;
    }
    .sustainability-point-card .point-card-icon {
        width: 70px;
    }
    .sustainability-point-card .point-card-body{
        width: calc(100% - 70px);
        padding-left: 30px;
    }
    .villa-panel .villa-card-body{
        font-size: 15px;
        margin-top: 8px;
    }
    .villa-card-icon img {
        height: 35px;
    }
    .villa-panel .villa-card{
        width: 135px;
        height: 135px;
        padding: 15px 10px;
    }
    .label-bottom-lines {
        height: 115px;
    }
    .villa-inner-visual ul li:nth-child(3) .label-bottom-lines {
        height: 123px;
    }
    .villa-inner-visual .bottom-list-points li:last-child .label-bottom-lines {
        height: 98px;
    }
    .bottom-list-points li:first-child .label-bottom-lines {
        height: 88px;
    }
    .villa-inner-visual ul {
        max-width: 425px;
        padding: 0;
    }
    .villas {
        padding: 40px 0;
    }
    .sustainability .sustainability-body {
        margin-top: 20px;
    }
    .partners-form-contact.tie-up{
        padding: 60px 0 35px;
    }
    .together-we-strong{
        padding-bottom: 0;
    }
    .find-us {
        padding: 60px 0;
    }
    .social-links{
        flex-wrap: wrap;
    }
    .social-links li{
        width: calc(100%/2);
        margin-bottom: 25px;
    }
    .social-links li:not(:last-child) a{
        border: 0;
    }
    .find-us {
        padding: 60px 0;
    }
    .contact-card-body p, .contact-card-body a{
        min-height: 68px;
    }
    .about-founder-text {
        padding-left: 40px;
    }
    .salil .about-founder-text{
        padding-right: 40px;
    }
    .main-banner .welcome-text {
        top: 320px;
    }
    .main-banner .explore-now {
        bottom: 12% !important;
    }
}

@media (max-width: 992px) {
    h1{
        font-size: 32px;
    } 
    .container-fluid-padding {
        padding-left: 15px;
        padding-right: 15px;
    }

    a.navbar-brand img {
        width: 100px;
    }

    .welcome-text {
        top: 53%;
        left: 47%;
        padding-left: 52px;
    }

    .window-wrapper .window-border {
        right: -24px;
        width: 206px;
        height: 298px;
    }

    .slider-visual img {
        width: 206px;
    }

    .slider-card .slider-visual {
        width: 206px;
    }

    .window-wrapper .blur-window {
        width: 265px;
        height: 330px;
    }

    .sustainability-slider {
        width: 611px;
    }
    .back-window {
        right: 72px;
    }

    .features-window .features-border {
        left: 17px;
    }

    .feature-wrapper .nav-pills .nav-item .nav-link span br{
        display: block;
    }

    .feature-wrapper .nav-pills .nav-item .nav-link .nav-tab-img {
        margin-right:0;
    }
    .sustainability, .intuitive-tech {
        padding: 40px 0;
      }
      .responsible-card-slides {
        margin-top: 55px;
      }
      .slider-dots{
        margin-left: auto;
        margin-right: auto;
        left: 0;
        bottom: 35px;
    }
    .small-heading hr{
        width: 100px;
    }
    .welcome-our-curiosity .textarea-field {
        height: 138px;
    }
}

@media (max-width: 991px){
    h2 {
        font-size: 25px;
        line-height: normal;
      }
    .iphone-frame {
        max-width: 275px;
    }
    .iphone-ui {
        max-width: 280px;
        padding-left: 8px !important;
    }
    .smart-tech-tabs .nav-link{
        margin: 10px 0;
        min-height: initial;
    }
    .smart-tech-tabs{
        align-items: flex-start;
    }
    .left-side-tabs {
        padding-right: 145px;
    }
    .right-side-tabs {
        padding-left: 153px;
    }
    .intuitive-living {
        padding: 35px 0 60px;
    }
    .intuitive-living .intuitive-luxury-text{
        margin: 0px;
    }
    .download-brochure-action .btn.theme-btn{
        padding: 12px 20px  ;
    }
    .curiosity-form .form-control{
        padding: 13px 15px;
    }
    .welcome-our-curiosity {
        padding: 40px 0;
    }
    .form-submit .btn.theme-btn {
        padding: 12.75px 40px;
        font-size: 15px;
    }
    footer .newsletter span, .footer-heading span{
        font-size: 20px;
    }
    footer .newsletter .form-control{
        padding: 13px 15px;
    }
    .btn.subscribe-btn{
        padding-left: 20px;
        padding-right: 20px;
    }
    .footer-links .footer-heading,
    .footer-links a{
        text-align: left;
    }
    .short-intro{
        margin: 40px 0;
    }
    .blog-detail li i {
        font-size: 17px;
        max-height: 8px;
    }
    .blog-detail li i{
        max-height: initial;
    }
    .pagination a {
        width: 40px;
        height: 40px;
        font-size: 15px;
    }
    .footer-about p{
        text-align: left;
    }
    .feature-wrapper .nav-pills .nav-item .nav-link{
        font-size: 16px;
        padding: 18px 12px;
    }
    .feature-inner-wrapper {
        padding: 50px 0 0;
    }
    .features-inner-slider {
        margin-top: 30px;
    }
    .intutive-luxury {
        padding: 90px 0 75px;
    }
    .intutive-luxury .window-large-visual{
        margin-left: 15px;
    }
    .brand-heritage-window .window-large-visual{
        margin-right: 20px;
    }
    .slider-card {
        padding-left: 0px;
    }
    .atamosphere-left-text{
        max-width: 230px;
    }
    .slider-card .slider-text p{
        margin: 20px 0;
        line-height: 1.7;
        padding-right: 0;
    }
    .atamosphere-left-text span:last-child{
        display: none
    }
    .wide-container {
        max-width: 720px;
    }
    .main-banner .social-media{
        transform: none;
        bottom: 0;
        top: initial;
    }
    .main-banner .social-media a{
        margin: 2px 0;
        width: 35px;
        height: 42px;
    }
    .atamosphere-image-slide {
        width: calc(100% - 230px);
    }
    .atamosphere-image-slide .active-slide .window-visual {
        max-width: 250px;
    }
    .front-window {
        transform: scale(0.5) translateX(615px);
        -o-transform: scale(0.5) translateX(615px);
        -ms-transform: scale(0.5) translateX(615px);
        -moz-transform: scale(0.5) translateX(615px);
        -webkit-transform: scale(0.5) translateX(615px);
    }
    .atamosphere-image-slide .demo-slide .window-visual {
        max-width: 250px;
    }
    .popular-post-card{
        flex-wrap: wrap;
    }
    .popular-post-card .popular-post-visual,
    .popular-post-card .popular-post-body{
        width: 100%;
        padding: 0;
    }
    .popular-post-card .popular-post-body{
        margin: 15px 0 0;
    }
    .popular-post-card .popular-post-body a{
        margin-bottom: 5px;
        font-size: 15px;
    }
    .popular-post-card:not(:last-child) {
        margin-bottom: 25px;
    }
    .blog-box h3 {
        margin-bottom: 12px;
    }
    .category ul li a{
        font-size: 15px;
    }
    .btn-border{
        font-size: 12px;
    }
    .blog-detail li:not(:last-child) {
        margin-right: 18px;
        padding: 0;
    }
    .banner-inner {
        padding: 190px 0 40px;
    }
    .logo-circle img {
        width: 45px;
    }
    .page-title-logo::before,
    .page-title-logo::after{
        width: calc(50% - 35px);
    }
    .customer-relation-visual{
        margin: 0 0 30px;
    }
    .customer-relation-visual img{
        max-width: initial;
    }
    .customer-relation {
        padding: 50px 0;
    }
    .contactus-form-description {
        padding-left: 0px;
    }
    .partners-contact-form{
        padding-right: 0px;
    }
    .contact-type-block ul{
        flex-wrap: wrap;
    }
    .contact-type-block li{
        min-width: initial;
        width: 50%;
        padding: 30px 40px !important;
    }
    .contact-type-block li:nth-child(2){
        border-right: 0;
    }
    .contact-type-block li:nth-child(2),
    .contact-type-block li:nth-child(3),
    .contact-type-block li:nth-child(4){
        min-width: initial;
    }
    .contactus-form .window-visual{
        margin-bottom: 35px;
    }

    .tie-up {
        padding: 0px 0 0px;
    }
    .contact-type-block{
        border: 0;
    }
    
    .contact-card-body .social-media{
        margin-top: 20px;
    }
    .properties-slider-inner {
        margin-right: -230px;
    }
    .next-prev-arrows a{
        width: 42px;
        height: 42px;
        font-size: 22px;
    }
    .our-properites {
        padding: 60px 0 0;
    }
    .single-property.property-active-slide {
        width: 470px;
    }
    .single-property {
        width: 285px;
        transform: translateX(484px);
        -o-transform: translateX(484px);
        -ms-transform: translateX(484px);
        -moz-transform: translateX(484px);
        -webkit-transform: translateX(484px);
    }
    .property-img {
        clip: rect(33px, 258px, 260px, 25px);
    }
    .single-property.property-active-slide .property-caption h2 {
        font-size: 30px;
    }
    .contact-type-block li{
        min-height: 218px;
    }
    .single-property + .single-property + .single-property {
        transform: translateX(783px);
        -o-transform: translateX(783px);
        -ms-transform: translateX(783px);
        -moz-transform: translateX(783px);
        -webkit-transform: translateX(783px);
    }
    .world-experience .download-brochure-action{
        text-align: right;
    }
    .property {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .property .property-block {
        margin-top: 25px;
    }
    .property .property-card .property-card-body{
        padding: 15px 0;
    }
    .investment .property-card .property-card-body p{
        line-height: 1.5;
    }
    .world-experience-tab{
        overflow-x: auto;
    }
    .world-experience-tab ul{
        min-width: 950px;
    }
    .media-gallery-slides {
        padding: 50px 0 35px;
    }
    .founder-info {
        padding: 50px 0;
    }
    .management {
        padding: 0 0 40px 0;
    }
    .sustainability-description{
        margin-top: 30px;
    }
    .villa-panel{
        margin-top: 35px;
        justify-content: center !important;
    }
    .villa-panel .villa-card{
        width: 150px;
        height: 150px;
        border-radius: 0 40% 0 40% !important;
    }
    .right-villa-panel .villa-card {
        border-radius: 40% 0 40% 0 !important;
    }
    .partners-form-contact.tie-up{
        padding: 40px 0;
    }
    .comfort-land-owner{
        padding: 35px 0 85px;
    }
    .work-step-card {
        max-width: 275px;
    }
    .find-us {
        padding: 40px 0;
    }
    .social-media-icon{
        width: 55px;
        height: 55px;
        font-size: 25px;
    }
    .media-username {
        width: calc(100% - 55px);
    }
    .sticky a.navbar-brand img{
        width: 150px;
    }
    a.navbar-brand img {
        width: 70px;
    }
    .navigation-menu{
        padding-top: 20px;
        padding-bottom: 20px;
    }
    footer .short-intro{
        padding-bottom: 0;
    }
    .founder-info {
        padding: 50px 0 35px;
    }
    .founder-info.salil{
        padding-top: 0;
    }
    .welcome-our-curiosity .textarea-field {
        height: 130px;
    }
    .contact-type-block li{
        padding: 15px 15px !important;
    }
    .contact-card-body p, .contact-card-body a{
        word-break: break-all;
    }
    .about-founder-text {
        padding-left: 0px;
    }
    .salil .about-founder-text{
        padding-right: 0px;
    }
}

@media (max-width: 800px) {

    .sustainability-slider {
        width: 100%;
    }

    .window-visual {
        width: 480px;
    }

    .features-window .features-border {
        height: 628px;
        width: 488px;
    }

    .welcome-text>span {
        font-size: 17px;
    }

    .welcome-text {
        padding-left: 21px;
    }

    .feature-wrapper .nav-pills .nav-item .nav-link.active .nav-tab-img {
        margin-right: 0;
    }

    .feature-tab-pane .feature-text .feature-body p {
        padding-right: 0;
    }


    .yellow-next {
        right: 29px;
    }

    .responsive-sm-hide {
        display: none !important;
    }
    
}

@media (max-width: 767px){
    h1 {
        font-size: 27px;
      }
    h2{
        font-size: 23px;
    }
    .small-heading hr{
        width: 75px;
    }
    .intuitive-living .secondary-heading{
        margin-bottom: 0;
    }
    .intuitive-living .intuitive-luxury-text{
        margin-bottom: 25px;
    }
    .intuitive-living {
        padding: 25px 0 40px;
      }
      .curiosity-form{
        flex-wrap: wrap;
      }
      .form-submit,
      .form-submit .btn.theme-btn{
        width: 100%;
      }
      .welcome-our-curiosity {
        padding: 50px 0;
      }
      .newsletter-input{
        max-width: 100%;
      }
      .newsletter span{
        margin-bottom: 20px;
        display: inline-block;
      }
      footer .newsletter{
        border-bottom: 0;
        margin-bottom: 0;
      }
      footer address{
        margin: 20px 0 40px;
      }
      .copyright{
        flex-wrap: wrap;
        border-top: 0;
        margin: 30px 0 0;
      }
      .copyright-text{
        order: 2;
        width: 100%;
        border-top: 1px var(--border-color) solid;
        padding-top: 35px;
        margin-top: 35px;
      }
      .privacy{
        order: 1;
        width: 100%;
        justify-content: space-between;
      }
      #scrolltop{
        width: 40px;
        height: 40px;
        font-size: 20px;
        bottom: 14px;
      }
      .feature-wrapper .nav-pills .nav-item{
        width: 25% !important;
      }
      .feature-wrapper .nav-pills .nav-item .nav-link{
        padding: 20px 8px;
        font-size: 13px;
      }
      .feature-inner-wrapper{
        padding-top: 50px;
      }
      .slider-card > .row{
        flex-direction: column-reverse;
      }
      .back-window{
        right: -30px;
      }
      .intutive-luxury .window-large-visual{
        margin-left: 0;
      }
      .brand-heritage-window .window-large-visual{
        margin-right: 0;
      }
      .intutive-luxury {
        padding: 50px 0 35px;
      }
      .intutive-luxury .window-large-visual,
      .atamosphere-image-slide .demo-slide .window-visual{
        max-width: 100%;
      }
      .intutive-luxury .window-large-visual img{
        width: 100%;
      }
      .intutive-luxury .features-window{
        padding-left: 70px;
      }
      .intutive-luxury .features-window.brand-heritage-window{
        padding-left: 0;
        padding-right: 70px;
      }
      .wide-container {
        padding-left: 20px;
        padding-right: 20px;
    }
    .features-window{
        margin: 0 auto;
    }
    .active-slide {
        transform: translateX(0);
        width: 100%;
        display: flex;
        justify-content: center;
        z-index: 1;
    }
    .atamosphere-image-slide .active-slide .window-visual{
        max-width: 100%;
    }
    .front-window {
        transform: scale(0.5) translateX(0);
        -o-transform: scale(0.5) translateX(0);
        -ms-transform: scale(0.5) translateX(0);
        -moz-transform: scale(0.5) translateX(0);
        -webkit-transform: scale(0.5) translateX(0);
        left: 0;
        right: 0;
    }
    .slider-progress .progress{
        display: none !important;
    }
    .main-slider button{
        margin: 0 !important;
    }
    .main-slider button.btn-next{
        position: absolute;
        right: 50px;
    }
    .main-slider .slider-progress{
        padding: 0 15px;
    }
    .slider-progress #slider-counter{
        margin: auto;
        font-size: 40px;
    }
    .slider-action-control{
        padding: 0 50px;
    }
    .atamosphere-left-text{
        max-width: 100%;
        padding: 0 15px;
        margin-top: 12px; 
    }
    .social-media, .atamosphere-left-text .btn{
        display: none !important;
    }
    .slider-text{
        text-align: center;
    }
    .atamosphere-image-slide{
        width: calc(100% - 100px);
        padding: 0 15px;
    }
    .main-banner .slider-bg-image::before{
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        background-color: rgba(0,0,0,0.5);
    }
    .banner-inner {
        padding-bottom: 30px;
    }
    .main-slider .slider-content {
        padding: 150px 0 30px;
    }
    .contact-type-block li,
    .contact-type-block li:nth-child(1),
    .contact-type-block li:nth-child(2){
        width: 100%;
        padding: 25px 20px !important;
        min-height: initial;
    }
    .contact-type-block li:nth-child(1),
    .contact-type-block li:nth-child(2){
        border-bottom: 1px #E7C16D solid;
    }
    .contact-type-block li:not(:last-child){
        border-right: 0;
    }
    .contact-type-block {
        padding: 0;
        border: 0;
    }
    .contact-card-body .social-media{
        display: inline-block !important;
    }
    .contact-type {
        padding: 15px 0;
    }
    .map {
        padding: 0px 0 0px;
    }
    .properties-slider-inner{
        margin-right: 0;
    }
    .our-properites .properties-tabs .nav-pills .nav-item .nav-link{
        padding: 15px 8px !important;
    }
    .our-properites .properties-tabs .nav-pills{
        flex-wrap: wrap !important;
    }
    .our-properites .properties-tabs .nav-pills .nav-item{
        width: 50% !important;
    }
    .our-properites .properties-tabs .nav-pills .nav-item:nth-child(2n+3) .nav-link {
        border-left: 1px solid var(--primary-color);
    }
    .properties-slider-inner {
        min-height: 270px;
    }
    .single-property {
        width: 220px;
        transform: translateX(460px);
        -o-transform: translateX(460px);
        -ms-transform: translateX(460px);
        -moz-transform: translateX(460px);
        -webkit-transform: translateX(460px);
    }
    .single-property.property-active-slide{
        width: calc(100% - 70px);
    }
    .property-img img {
        height: 240px;
    }
    .property-img {
        clip: rect(20px, 198px, 200px, 20px);
    }
    .single-property + .single-property + .single-property {
        transform: translateX(694px);
        -o-transform: translateX(694px);
        -ms-transform: translateX(694px);
        -moz-transform: translateX(694px);
        -webkit-transform: translateX(694px);
    }
    .single-property.property-active-slide .property-caption h2 {
        font-size: 25px;
    }
    .world-experience .download-brochure-action{
        text-align: left;
        margin: 20px 0 0;
    }
    .world-experience-tab ul{
        min-width: initial;
    }
    .world-experience-tab ul{
        justify-content: center !important;
        gap: 0;
    }
    .world-experience-tab ul li{
        width: 33.33%;
        padding: 5px;
    }
    .world-experience-tab ul li button{
        min-height: 142px;
    }
    .world-experience-tapcontent .img-fluid{
        width: 100%;
    }
    .founder-wrap{
        margin: 0 auto 25px;
    }
    .about-founder-text{
        text-align: center !important;
    }
    .founder .row .col-12:nth-child(even) .founder-info .row{
        flex-direction: column-reverse;
    }
    .founder-info {
        padding: 30px 0;
    }
    .management {
        padding: 30px 0 30px 0;
    }
    .management-card .management-visual img {
        width: 100%;
    }
    .management *{
        text-align: center;
    }
    .management-action{
        margin-top: 25px;
    }
    .villa-panel{
        gap: 10px;
    }
    .villa-panel .villa-card{
        width: 121px;
        height: 121px;
        padding: 15px 7px;
        border-radius: 0 30% 0 30% !important;
    }
    .villa-panel .villa-card-body{
        font-size: 14px;
    }
    .partners-contact-form{
        margin-bottom: 35px;
    }
    .partners-form-contact.tie-up{
        padding: 30px 0 0;
    }
    .work-step-card {
        max-width: 215px;
    }
    .work-step::before{
        height: calc(100% - 20%);
    }
    .work-step-card .work-step-card-icon{
        width: 55px;
        height: 55px;
        border-width: 4px;
        outline-width: 2px;
    }
    .work-step-card .work-step-card-icon{
        font-size: 22px;
    }
    .find-us {
        padding: 30px 0 0px;
    }
    .main-banner .welcome-text{
        top: 35%;
    }
    .main-banner .explore-now {
        bottom: 30% !important;
    }
    .founder-img{
        margin: 15px 0  10px;
    }
    .founder-message .position-name {
        font-size: 16px;
        justify-content: center !important;
        color: #333; 
    }
    .founder-message .position-right-alt::before {
        order: initial;
        margin-left: 0;
    }
    .sustainability .slick-prev{
        left: -12px;
    }
    .sustainability .slick-next{
        right: -12px;  
    }
    .founder-wrap .window-visual{
        margin-top: 25px;
    }
    .comfort-block .comfort-visual{
        width: 100%;
    }
    .comfort.comfort-land-owner .comfort-panel {
        position: relative !important;
        margin-top: 35px;
    }
    .comfort-land-owner{
        padding: 0 0 45px;
    }
    .intuitive-tech .slick-prev{
        left: 12px;
    }
    .features-inner-slider .slick-prev{
        left: -12px;
    }
    .intuitive-tech .slick-prev::before,
    .intuitive-tech .slick-next::before{
        content: "\f053";
        color: #333;
        font-family: FontAwesome;
    }
    .intuitive-tech .slick-next{
        right: 12px;
    }
    .features-inner-slider .slick-next{
        right: -12px;
    }
    .intuitive-tech .slick-next::before{
        content: "\f054";
    }
    .smart-slide-caption .intuitive-card-body{
        text-align: left;
    }
}

@media (max-width: 580px) {
    .yellow-next {
        right: 56px;
    }

    .window-visual {
        width: 93%;
    }
    
    .features-window .features-border {
        height: 596px;
        width: 457px;
        left: 49px;
    }
}

@media (max-width: 575px){
    .feature-wrapper .nav-pills .nav-item .nav-link{
        padding: 20px 15px;
    }
    .feature-wrapper .nav-pills .nav-item:last-child .nav-link{
        padding: 20px 45px;
    }
    .container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl{
        padding-left: 20px;
        padding-right: 20px;
    }
    .intutive-luxury .window-thumb-visual {
        max-width: 175px;
    }
    .blog-detail{
        flex-wrap: wrap;
    }
    .blog-detail li{
        margin-bottom: 8px;
    }
    .contact-type-block li{
        width: 100%;
    }
    .contact-type-block li:not(:last-child) {
        border-right: 0;
        border-bottom: 1px var(--primary-color) solid;
    }
    .contact-type-block li:nth-child(1), 
    .contact-type-block li:nth-child(2){
        min-height: initial;
    }
    .single-property.property-active-slide {
        width: 100%;
    }
    .single-property,
    .single-property + .single-property + .single-property{
        transform: translateX(800px);
        -o-transform: translateX(800px);
        -ms-transform: translateX(800px);
        -moz-transform: translateX(800px);
        -webkit-transform: translateX(800px);
    }
    .world-experience-tab ul li{
        width: 50%;
    }
    .villa-inner-visual{
        display: flex;
        flex-direction: column;
        padding: 0;
    }
    .villa-inner-visual > img{
        order: 2;
    }
    .villa-inner-visual ul{
        position: relative !important;
        order: 1;
    }
    .villa-inner-visual ul + ul{
        order: 3;
    }
    .work-step-card{
        max-width: initial;
        width: calc(50% - 45px);
    }
    .social-links li {
        width: calc(100%/1);
    }
    .social-media-icon{
        width: 45px;
        height: 45px;
        font-size: 20px;
    }
    .media-username {
        width: calc(100% - 45px);
    }
    .top-bar .contact-information{
        padding-right: 0;
    }
    .navigation-menu .navbar-toggler{
        margin-right: 15px;
    }
    .top-bar .contact-information a{
        font-size: 13px;
    }
    .top-bar .contact-information a img,
    .top-bar .contact-information a i{
        margin-right: 6px !important;
    }
}

@media (max-width: 530px) {
    .features-window .features-border {
        height: 541px;
        width: 416px;
    }
    .feature-wrapper .nav-pills .nav-item:last-child .nav-link {
        padding: 20px 35px;
      }
}

@media (max-width: 492px) {
    .features-window .features-border {
        height: 555px;
        width: 429px;
    }
    .intuitive-visual img {
        width: 100%;
    }
    .feature-wrapper .nav-pills .nav-item .nav-link span {
        font-size: 12px;
    }
    .intutive-luxury {
        padding: 100px 0 80px;
    }    
    .sustainability-body p {
        padding-right: 0;
    }
    .yellow-next {
        right: 43px;
    }
    .features-window .features-border {
        height: 558px;
        width: 421px;
        left: 24px;
    }
    .feature-wrapper .nav-pills .nav-item:last-child .nav-link{
        padding: 20px 35px;
    }
    .sustainability-point-card{
        flex-wrap: wrap;
    }
    .sustainability-point-card .point-card-icon{
        border-right: 0;
    }
    .sustainability-point-card .point-card-body{
        width: 100%;
        padding: 0;
        margin-top: 20px;
    }
    .f-wraper {
        min-height: 185px;
    }
    .sustainability .sustainability-card{
        padding: 15px 10px;
    }
    .sustainability-slider-body p{
        line-height: 1.5;
    }
    .feature-body .f-wraper span{
        min-height: 51px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media (max-width: 410px) {
    .features-window .features-border {
        height: 466px;
        width: 350px;
        left: 24px;
    }
    .slider-item .smart-slide-img,
    .smart-slide-img{
        max-width: 250px;
        height: 320px;
    }
    .slider-inner-wrap::before{
        width: 260px;
        height: 320px;
    }
    .feature-wrapper .nav-pills .nav-item:last-child .nav-link {
        padding: 20px 25px;
    }
    .main-banner .welcome-text {
        top: 41%;
    }
    .main-banner .explore-now {
        bottom: 21% !important;
    }
    .slider-item .smart-slide-img img {
        max-width: 254px;
        margin: 0 auto;
        border-radius: 35px;
    }
}

@media (max-width: 380px) {
    .feature-wrapper .nav-pills .nav-item .nav-link {
        padding: 28px 8px;
    }


    .features-window .features-border {
        height: 439px;
        width: 333px;
    }

    .window-visual {
        width: 91%;
    }
 
    .sustainability-slider {
        width: 317px;
        margin-left: 0;
    }

    .feature-wrapper .nav-pills .nav-item .nav-link {
        padding: 15px 8px;
    }

   .feature-tab-pane {
    overflow: hidden;
   }

   .features-inner-slider .feature-body {
        margin-top: 20px;
    }

    .features-inner-slider {
        width: calc(100% + 57%);
    }

    .features-window .features-border {
        height: 436px;
        width: 317px;
    }
    .f-wraper {
        min-height: 150px;
    }
    .features-inner-slider .slick-prev {
        left: -5px;
    }
    .features-inner-slider .slick-next {
        right: inherit;
        left: 58%;
    }
}