/*----------------------------------------------

[Core CSS]

Theme   : Atmosphere Living
Version : 1.0.0
Author  : Digitech Software Solutions
Support : https://www.digitechsoft.com/

----------------------------------------------*/

/*----------------------------------------------

[Content Index]

1. General
2. Layout
3. Color
4. Style
5. Animation
6. Hover
7. Helper

----------------------------------------------*/

/*----------------------------------------------
1. General
----------------------------------------------*/

/* #region General */

/* @media (min-width: 1400px) {
    .container-custom {
        max-width: 1707px;
    }
} */
:root {
    --body-color: #FFFBF4;
    --primary-color: #E0B462;
    --primary-15-color: #E0B462;
    --secondary-color: #008080;
    --red-shade-1: #3C7D73;
    --accent-color: #fedc00;
    --success-color: #007a4d;
    --info-color: #023e8a;
    --warning-color: #ff9800;
    --danger-color: #e63946;
    --white-color: #fff;
    --gray-color: #606060;
    --black-color: #333333;
}
p {
    color: var(--black-color);
}

a {
    text-decoration: none;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

a:not([href]):not([class]),
a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none;
}

.btn-atmosphere{
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    border-radius: 0px;
    padding: 14px 29px;
    border: 0px;
}

.btn-yellow {
    color: var(--white-color);
    background-color: var(--primary-color);
    transition: 0.4s;
}

.btn-yellow:hover, .btn-yellow:focus, .btn-yellow:active {
    background-color: var(--primary-15-color) !important;
    color: var(--white-color) !important;
    margin-right: 15px;
}

.btn-green {
    color: var(--white-color);
    background-color: #9FCDA0;
}

.btn-green:hover, .btn-green:focus, .btn-green:active {
    background-color: #79aa7a !important;
    color: var(--white-color) !important;
}