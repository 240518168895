/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

/*----------------------------------------------

[Main CSS]

Theme   : Atmosphere Living
Version : 1.0.0
Author  : Digitech Software Solutions
Support : https://www.digitechsoft.com/

----------------------------------------------*/

/*----------------------------------------------

[Content Home]

1. Header
2. Main Banner
3. Basic Intro
4. Property
5. Comfort
6. Privilege
7. Sustainability 
8. Contact Form
9. Footer

----------------------------------------------*/

/*----------------------------------------------

[Content Page]

1. Sustainability
    1.1 Point

----------------------------------------------*/
:root {
    --white-color: #ffffff;
    --body-color: #FFFBF3;
    --primary-color: #E7C16D;
    --dark-yellow-color: #D2AE5F;
    --secondary-color: #2d5e59;
    --black-color: #000000;
    --black-shade-1: #333333;
    --extra-light-yellow: #FFFBF4;
    --medium-yellow: #FBF3E7;
    --green-color: #92B893;
    --green-wet: #9FCDA0;
    --border-color: #ECE1CC;
    --light-cream: #FFFAF3;
    --light-yellow: #F6F1E4;
    --light-gray: #505050;
    --gray-500: #606060;
    --gray-border: #C8C8C8;
    --secondary-border: #A7A7A7;
    --mute-border: #D9D9D9;
    --nav-dropdown-border: #6A6A6A;
    --primary-font: 'Vintage Glamour';
    --secondary-font: 'Public Sans', sans-serif;
    --dunbar-tall: 'DunbarTall';
    --dunbar-tall-book: 'DunbarTallBook';
}
::selection{
    background-color: var(--secondary-color);
    color: var(--bs-white);
}
/*Typography*/

@font-face {
    font-family: 'Vintage Glamour';
    src: url(../fonts/vintage-glamour/VintageGlamourRegular.woff2);
}
@font-face {
    font-family: 'DunbarTall';
    src: url(../fonts/dunbar-tall/DunbarTall-Regular.woff2) format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'DunbarTallBook';
    src: url(../fonts/dunbar-tall/DunbarTall-Book.woff2) format('woff2');
    font-weight: normal;
    font-style: normal;
}

body {
    font-family: var(--secondary-font);
    background-color: var(--extra-light-yellow);
    overflow-x: hidden;
    padding-right: 0 !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-bottom: 0;
}
h1 {
    font-family: 'DunbarTall';
    font-size: 50px;
}
h2 {
    font-size: 32px;
    font-family: 'DunbarTall';
}
h3{
    font-size: 20px;
    font-family: 'DunbarTall';
}
p {
    color: var(--black-shade-1);
    line-height: 30px;
}

img{
    max-width: 100%;
}
.smooth{
    transition: all 400ms ease-in-out 0s;
    -o-transition: all 400ms ease-in-out 0s;
    -ms-transition: all 400ms ease-in-out 0s;
    -moz-transition: all 400ms ease-in-out 0s;
    -webkit-transition: all 400ms ease-in-out 0s;
}

.atamosphere-input{
    padding: 12px 20px;
    border-color: var(--secondary-border);
    min-height: 50px;
    color: var(--black-shade-1);
}
.atamosphere-input:focus{
    border-color: var(--dark-yellow-color);
    box-shadow: none;
}

/*--Reset--*/

body {
    background-color: var(--body-color);
}

.container-fluid-padding {
    padding-left: 80px;
    padding-right: 80px;
}

a {
    text-decoration: none;
}
/*--Button--*/
.btn-border-yellow {
    background-color: transparent;
    padding: 12px 32px;
    border-radius: 0px;
    color: var(--white-color);
    border: 1px solid var(--dark-yellow-color);
    text-transform: uppercase;
}

.btn-border-yellow:hover,
.btn-border-yellow:focus,
.btn-border-yellow:active {
    background-color: var(--dark-yellow-color) !important;
    color: var(--white-color) !important;
}
.btn-border-yellow:hover,
.btn-border-yellow:focus,
.btn-border-yellow:active {
    background-color: var(--dark-yellow-color) !important;
    color: var(--white-color) !important;
}
/*----------------------------------------------
1. Header
----------------------------------------------*/

/* #region Header */

header {
    position: relative;
    width: 100%;
    top: 0;
    z-index: 99999;
}

header.sticky {
    position: fixed;
    z-index: 99999;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
    background-color: var(--secondary-color);
}

header .top-bar {
    background-color: var(--secondary-color);
    padding-top: 12.5px;
    padding-bottom: 12.5px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
}

header .contact-information {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    max-width: 400px;
    margin-left: auto;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

header .contact-information a {
    color: var(--white-color);
    line-height: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 14px;
}
header .contact-information a img {
    display: inline-block;
    margin-right: 8px;
    width: 15px;
    height: auto;
}

.top-bar .contact-information {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    max-width: 400px;
    margin-left: auto;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: flex-end;
}
/* .top-bar .contact-information .contact-number{
    display: none;
} */
.top-bar .contact-information a {
    color: var(--white-color);
    line-height: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 14px;
}
.top-bar .contact-information a i{
    color: var(--dark-yellow-color);
}
.top-bar .contact-information a img {
    display: inline-block;
    margin-right: 8px;
    width: 15px;
    height: auto;
}
.navigation-menu {
    background-color: var(--secondary-font);
    padding-top: 33px;
    padding-bottom: 33px;
    -webkit-transition: all 400ms ease-in-out 0s;
    -o-transition: all 400ms ease-in-out 0s;
    transition: all 400ms ease-in-out 0s;
    position: fixed;
    left: 0;
    right: 0;
    top: 39px;
}
.sticky .not-fixed-on-top {
    opacity: 0;
    visibility: hidden;
    height: 0;
  }
  .fixed-on-top {
    top: -120px;
    background-color: var(--secondary-color);
    padding: 10px 0;
  }
  .sticky .fixed-on-top {
    top: 0;
  }
.navigation-menu .navbar-nav li a {
    color: var(--white-color);
    font-family: var(--secondary-font);
    line-height: 34px;
    text-transform: capitalize;
}
.navigation-menu .navbar-nav li a:hover {
    color: var(--dark-yellow-color) !important;
}
.navigation-menu .navbar-nav li a.active,
.navigation-menu .navbar-nav .nav-link.show {
    color: var(--dark-yellow-color) !important;
}
.navigation-menu .dropdown-menu,
.navigation-menu .dropdown-menu li a {
    background-color: #00000000;
    border: 0px;
}
.navigation-menu .dropdown-menu li a {
    line-height: 32px;
    text-transform: capitalize;
}

.navigation-menu .dropdown-toggle::after {
    content: "\f107";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    display: inline-block;
    vertical-align: middle;
    margin-left: 5px;
    border: 0;
}

header .btn-close {
    background-size: 12px;
}
.navigation-menu .navbar-toggler {
    border: 0px;
    -webkit-box-shadow: none;
    box-shadow: none;
    padding: 0;
}

.navigation-menu .bar1,
.navigation-menu .bar2,
.navigation-menu .bar3 {
    width: 31px;
    height: 2px;
    background-color: var(--white-color);
    margin: 6px 0;
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
}

.navigation-menu .bar3 {
    width: 15px;
}

.navigation-menu .offcanvas-menu {
    background: rgba(51, 51, 51, 0.8);
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);
    padding-left: 3%;
    padding-right: 25px;
    padding-top: 15px;
    max-width: 380px;
    width: 100%;
}
.navigation-menu .offcanvas-menu .offcanvas-body .navbar-nav li a {
    color: var(--white-color);
    line-height: 34px;
    text-transform: capitalize;
}
.navigation-menu .offcanvas-menu .offcanvas-body li a:hover {
    color: var(--dark-yellow-color);
}
.navigation-menu .offcanvas-menu .dropdown-menu,
.navigation-menu .offcanvas-menu .dropdown-menu li a {
    background-color: unset;
    border: 0px;
}
.navigation-menu .offcanvas-menu .offcanvas-body .dropdown-menu li a {
    line-height: 32px;
    text-transform: capitalize;
    max-width: 130px;
    padding: 7px 0;
    border-top: 1px var(--nav-dropdown-border) solid;
}
.navigation-menu .offcanvas-menu .offcanvas-body .dropdown-menu li:last-child a{
    border-bottom: 1px var(--nav-dropdown-border) solid;
}

.navigation-menu .offcanvas-menu .btn-close {
    color: #ffffff;
    -webkit-filter: brightness(0) invert(1);
    filter: brightness(0) invert(1);
    opacity: 1;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.visit-atamosphere{
    margin-top: 2.5rem;
}
.visit-atamosphere span{
    font-size: 14px;
}
.visit-now{
    border-top: 1px var(--bs-white) solid;
    border-bottom: 1px var(--bs-white) solid;
}



/* #endregion Header */


/*----------------------------------------------
2. Main Banner
----------------------------------------------*/

/* #region Main Banner */
.main-banner {
    padding-top: 39px;
}
.main-banner .banner-slider,
.main-banner .banner-slider .main-slider,
.main-banner .banner-slider .slick-list,
.main-banner .banner-slider .slick-track,
.main-banner .banner-slider .slick-slide {
    height: 100%;
    position: relative;
}

.main-slider .slider-content {
    padding: 110px 0 75px;
}

.slider-bg-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.main-slider .slider-content .slider-body {
    /* position: absolute;
    top: 0;
    height: 100%;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center; */
    position: relative;
    z-index: 1;
}

.slider-card .slider-text h1 {
    color: var(--white-color);
}

.slider-card .slider-text h1 span {
    color: var(--dark-yellow-color);
}

.slider-card .slider-text p {
    padding-right: 50px;
    color: var(--white-color);
    margin: 50px 0;
}
.main-slider button {
    z-index: 9;
    width: 66px;
    height: 66px;
    border: 1px solid var(--white-color);
    display: flex;
    align-items: center;
    justify-content: center;
}

.slide-image {
    position: relative;
}
.slide-image::before {
    position: absolute;
    content: "";
    left: 0px;
    width: 100%;
    background-color: #00000059;
    top: 0px;
    z-index: 9;
    height: 100%;
}
.main-banner .enquire {
    display: inline-block;
    position: absolute;
    top: 28%;
    right: -45px;
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    z-index: 9;
}
.main-banner .enquire a {
    background-color: var(--dark-yellow-color);
    color: var(--black-color);
    text-transform: uppercase;
    font-weight: 600;
    font-size: 13px;
    padding: 19px 35px;
    line-height: 0;
    display: inline-block;
    -webkit-appearance: unset !important;
}
.main-banner .social-media {
    /* display: inline-block; */
    position: absolute;
    top: 65%;
    left: 100px;
    transform: translateY(-50%);
    z-index: 9999;
    display: none;
}
.main-banner .social-media a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 40px;
    height: 45px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    color: var(--white-color);
    font-size: 21px;
    margin: 10px 0;
}
.main-banner .slide-image img {
    width: 100%;
}
.main-banner .main-banner-slider .slide-content {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 15%;
    z-index: 9;
}
.main-banner .main-banner-slider .slide-content h1 {
    color: var(--white-color);
    line-height: 82px;
}

.main-banner-slider .slide-content h1 span {
    color: var(--dark-yellow-color);
}

.main-banner .slide-content .main-action {
    margin-top: 83px;
}

.main-banner .main-action .btn-sweep-right {
    border: 0px;
    display: inline-block;
}

.main-banner .main-action .btn-sweep-right span {
    position: relative;
    border: 1px solid var(--dark-yellow-color) !important;
    border-radius: 0px;
    text-transform: uppercase;
    color: var(--white-color);
    padding: 24px 29px;
    line-height: 0;
    font-size: 14px;
    font-weight: 500;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    display: inline-block;
    letter-spacing: 0.1em;
}

.main-banner .main-action .btn-sweep-right span::before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--dark-yellow-color);
    -webkit-transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 0 50%;
    -ms-transform-origin: 0 50%;
    transform-origin: 0 50%;
    -webkit-transition-property: transform;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    -o-transition-property: transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    -o-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
}

.main-banner .main-action .btn-sweep-right:hover span:before {
    -webkit-transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform: scaleX(1);
}

.main-banner .main-action .btn-sweep-right img {
    -webkit-transform: translateX(40px);
    -ms-transform: translateX(40px);
    transform: translateX(40px);
    -webkit-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    -o-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
}

.main-banner .main-action .btn-sweep-right:hover img {
    -webkit-transform: translateX(50px);
    -ms-transform: translateX(50px);
    transform: translateX(50px);
}

.main-banner .feature {
    position: absolute;
    bottom: -60px;
    border-top: 3px solid var(--dark-yellow-color);
    background-color: var(--body-color);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: space-evenly;
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly;
    padding-top: 28px;
    padding-bottom: 28px;
    max-width: calc(100% - 33px);
    left: 0;
    right: 0;
    margin: 0 auto;
    -webkit-box-shadow: 0px 30px 50px rgba(236, 225, 204, 0.5);
    box-shadow: 0px 30px 50px rgba(236, 225, 204, 0.5);
    z-index: 9;
}

.main-banner .feature .feature-card {
    text-align: center;
    -webkit-box-flex: 2;
    -ms-flex-positive: 2;
    flex-grow: 2;
    border-right: 1px solid var(--dark-yellow-color);
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    position: relative;
    cursor: pointer;
}

.main-banner .feature .feature-card:last-child {
    border-right: 0;
}

.main-banner .feature-card .feature-icon img {
    width: 42px;
    height: 42px;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}

.main-banner .feature-card:hover img {
    -webkit-transform: scale(0.8);
    -ms-transform: scale(0.8);
    transform: scale(0.8);
}

.main-banner .feature-card .feature-body {
    padding: 3px 10px;
}

.main-banner .feature-card .feature-body span,
.main-banner .feature-card .feature-overlay span {
    display: block;
    margin-top: 25px;
    font-size: 16px;
    color: var(--black-color);
}

.main-banner .feature-card .feature-body span {
    text-transform: uppercase;
}

.main-banner .feature .feature-card:nth-child(even) .feature-body span {
    color: var(--secondary-color);
}

.main-banner .feature-card .feature-overlay {
    position: absolute;
    bottom: 0;
    width: 100%;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    background-color: var(--body-color);
    padding: 3px 10px;
    opacity: 0;
}

.main-banner .feature-card .feature-overlay span {
    margin-top: 0px;
}

.main-banner .feature-card .feature-overlay::before {
    position: absolute;
    content: "";
    width: 0px;
    height: 0px;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 14px solid var(--dark-yellow-color);
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: -29px;
    opacity: 0;
}

.main-banner .feature-card:hover .feature-overlay,
.main-banner .feature-card:hover .feature-overlay::before {
    opacity: 1;
}

.main-banner .feature-card:nth-child(even):hover .feature-overlay::before {
    border-bottom-color: var(--secondary-color);
}

/* #endregion Main Banner */

/*----------------------------------------------
3. Basic Intro
----------------------------------------------*/

/* #region Basic Intro */

.basic-intro {
    position: relative;
    margin-top: 180px;
    margin-bottom: 70px;
    overflow: hidden;
    min-height: 564px;
}

.basic-intro .basic-intro-visual {
    position: relative;
    right: 0;
    top: 0;
    width: 50%;
    z-index: -1;
    margin-left: auto;
}

.basic-intro .basic-intro-block {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: 0;
}

.basic-intro-heading-panel {
    background: -webkit-gradient(linear, left top, right top, color-stop(45.21%, #e7c16dc2), color-stop(75%, rgba(255, 255, 255, 0)));
    background: -o-linear-gradient(left, #e7c16dc2 45.21%, rgba(255, 255, 255, 0) 75%);
    background: linear-gradient(90deg, #e7c16dc2 45.21%, rgba(255, 255, 255, 0) 75%);
    padding-top: 55px;
    padding-bottom: 45px;
    margin-top: 57px;
    margin-bottom: 35px;
}

.basic-intro-heading-panel.basic-intro-heading h2 {
    color: var(--black-color);
}

.basic-intro-heading-panel .basic-intro-heading span {
    color: var(--red-shade-1);
}

.basic-intro .basic-intro-description .basic-intro-action {
    padding-top: 24px;
}

/* #endregion Basic Intro */

/*----------------------------------------------
4. Property
----------------------------------------------*/

/* #region Property */

.property {
    padding-top: 90px;
    padding-bottom: 50px;
}

.property .property-block {
    margin-top: 45px;
}

.property .property-card .property-card-body {
    background-color: var(--secondary-color);
    padding: 21px 0;
}

.property .property-card .property-card-body span {
    font-size: 20px;
    font-family: var(--dunbar-tall);
    margin-bottom: 10px;
    margin-top: 5px;
}
.property .property-block .property-column:nth-child(odd) .property-card .property-card-body {
    background-color: var(--dark-yellow-color);
}

/* #endregion Property */

/*----------------------------------------------
5. Concept Lifestyle
----------------------------------------------*/

/* #region Concept Lifestyle */

.concept-lifestyle {
    padding-top: 50px;
    padding-bottom: 50px;
    position: relative;
}

.concept-lifestyle-common {
    position: relative;
}

.concept-lifestyle .concept-lifestyle-common .concept-lifestyle-visual {
    width: 50%;
}

.concept-lifestyle .concept .concept-lifestyle-visual-box,
.concept-lifestyle .lifestyle .concept-lifestyle-visual-box {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
}

.concept-lifestyle .lifestyle .concept-lifestyle-visual {
    margin-left: auto;
}

.concept-lifestyle .concept-lifestyle-card .concept-lifestyle-heading {
    margin-bottom: 25px;
}

/* .concept-lifestyle .concept-lifestyle-card .concept-lifestyle-action {
    padding-top: 24px;
} */

/*--------------------------*/
.comfort {
    padding-top: 70px;
    padding-bottom: 70px;
    position: relative;
}

.comfort-block .comfort-visual {
    width: 50%;
}

.comfort-type-two .comfort-visual {
    margin-left: auto;
}

/* #endregion Concept Lifestyle */

/*----------------------------------------------
6. Privilege
----------------------------------------------*/

/* #region Privilege */

.privilege {
    padding-top: 50px;
    padding-bottom: 50px;
}

.privilege .privilege-card .privilege-heading {
    margin-bottom: 25px;
}

.privilege .privilege-list {
    position: relative;
    min-height: 500px;
    display: flex;
    align-items: center;
}

.privilege .privilege-list .privilege-visual {
    width: 58%;
}

.privilege .privilege-list .privilege-box {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
}

.customer-relation-point > p {
    margin: 20px 0 30px;
    color: var(--gray-500);
}

.privilege-accordion {
    padding-left: 25px;
    padding-right: 25px;
}

.privilege-accordion .accordion {
    background-color: transparent;
    border-top: 1px solid #ECE1CC;
}

.privilege-accordion .accordion-item {
    border: 0px;
    border-radius: 0px;
    border-bottom: 1px solid #ECE1CC;
    background-color: transparent;
}

.privilege-accordion .accordion-item:first-of-type .accordion-button {
    border-radius: 0px;
}

.privilege-accordion .accordion-item button {
    font-size: 20px;
    padding-top: 23px;
    padding-bottom: 23px;
    -webkit-box-shadow: none;
    box-shadow: none;
    background-color: transparent;
    color: var(--black-shade-1);
}
.privilege-accordion .accordion-item button[aria-expanded="true"],
.privilege-accordion .accordion-item button:hover {
    color: var(--primary-15-color);
}
.privilege-accordion .accordion-item button span {
    display: inline-block;
    padding-right: 50px;
    width: 25px;
}
.accordion-button:not(.collapsed)::after{
    background-image: url("../images/icon/golden-down-arrow.svg");
}
.privilege-accordion .accordion-item .accordion-body {
    padding-top: 0px;
    max-width: calc(100% - 50px);
    margin-left: auto;
}
.privilege-accordion .accordion-button::after{
    background-size: 15px;
}
/* #endregion Privilege */


/*----------------------------------------------
6. Lifestyle 
----------------------------------------------*/

/* #region Lifestyle  */

.lifestyle .lifestyle-tab ul button {
    border-radius: 0px;
    background-color: #fff0;
    color: #333333;
    border: 1px solid #E7C16D;
    width: 100%;
    font-size: 22px;
    padding: 15px;
    font-family: var(--primary-font);
}

.lifestyle .lifestyle-tab ul .active {
    background-color: #E7C16D;
    color: #333333;
}

.lifestyle .lifestyle-tab ul li {
    -webkit-box-flex: 2;
    -ms-flex-positive: 2;
    flex-grow: 2;
}

.lifestyle .tab-primary-visual {
    height: 100%;
}

.lifestyle .tab-primary-visual img {
    width: auto;
    height: 100%;
}

.lifestyle .card-block {
    position: relative;
}

.lifestyle .card-block .card-image img {
    width: 100%;
}

.lifestyle .card-block .card-description {
    position: absolute;
    bottom: 0;
    z-index: 9;
    background-color: #E7C16D;
    color: #333333;
    width: 100%;
    text-align: center;
    padding: 15px;
}

.tab-secondary-visual {
    height: 100%;
}

.tab-secondary-visual img {
    height: 100%;
}

/* #endregion Lifestyle */

/*----------------------------------------------
7. Sustainability 
----------------------------------------------*/

/* #region Sustainability   */

.sustainability {
    position: relative;
    padding: 120px 0 60px;
}

/* .sustainability .sustainability-visual {
    width: 45%;
} */
.sustainability .sustainability-body {
    margin-top: 34px;
}

.sustainability .sustainability-panel {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
}

.sustainability .heading-type-two h2 span {
    color: var(--secondary-color);
}

.sustainability .eco-friendly-features .accordion-item {
    background-color: transparent;
    border: 1px solid #E6E6E6;
    margin-bottom: 10px;
    border-radius: 4px;
}

.sustainability .eco-friendly-features .accordion-item:nth-child(even) img {
    border-color: var(--secondary-color);
}

.sustainability .eco-friendly-features button {

    background-color: transparent;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    font-size: 24px;
    color: #333333;
    padding-top: 20px;
    padding-bottom: 20px;

}

.sustainability .eco-friendly-features {
    margin-top: 50px;
}

.sustainability .eco-friendly-features button img {
    display: inline-block;
    padding-right: 35px;
    margin-right: 35px;
    border-right: 3px solid #A8D1A9;
}

.sustainability .eco-friendly-features .accordion-body span {
    display: block;
    max-width: 670px;
    margin-left: auto;
    padding-right: 20px;
    color: var(--gray-500);
}

.sustainability .eco-friendly-shapes {
    margin-top: 25px;
}

.sustainability .eco-friendly-shapes ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
    justify-content: space-around;
}

.sustainability .eco-friendly-shapes ul li {
    width: 120px;
    height: 120px;
    background-color: #689A92;
    border-radius: 0px 40%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.sustainability .eco-friendly-shapes ul li span {
    font-size: 16px;
    margin-top: 20px;
    color: #ffffff;
}

.sustainability .eco-friendly-shapes ul li:nth-child(2n) {
    background: var(--secondary-color);
    border-radius: 40% 0;
}

/* #endregion Sustainability */

/*----------------------------------------------
8. Contact Form
----------------------------------------------*/

/* #region Contact Form  */

.contact-form {
    margin-top: 60px;
    padding-top: 77px;
    padding-bottom: 77px;
    background-color: var(--dark-yellow-color);
}

.contact-form-input input {
    border: 0px !important;
    height: 60px;
    border-radius: 0px;
    background: rgba(255, 255, 255, 0.3);
    -webkit-box-shadow: 0px 4px 20px #DEB353;
    box-shadow: 0px 4px 20px #DEB353;
    padding-left: 26px;
    padding-right: 26px;
}

.contact-form-input button {
    height: 60px;
    border-radius: 0px;
    background-color: var(--black-color);
    border: 0;
    color: var(--white-color);
}

.contact-form-radio input {
    background-color: transparent;
    border-color: var(--black-color);
}


/* #endregion Contact Form */

/*----------------------------------------------

[Content Page]

1. Sustainability

----------------------------------------------*/

/*----------------------------------------------
1.1 Point
----------------------------------------------*/

/* #region Point */

.banner-inner {
    background-color: rgba(0, 0, 0, 0.7);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 225px 0 70px;
    background-blend-mode: overlay;
    position: relative;
}

.banner-inner .banner-title {
    color: var(--white-color);
    position: relative;
    z-index: 9;
}
.page-title-logo{
    max-width: 990px;
}
.page-title-logo::before,
.page-title-logo::after{
    content: "";
    position: absolute;
    left: 0;
    height: 1px;
    top: 0;
    bottom: 0;
    margin: auto;
    background-color: var(--dark-yellow-color);
    width: calc(50% - 45px);
}
.page-title-logo::after{
    left: initial;
    right: 0;
}
.logo-circle img{
    width: 55px;
}
.banner-inner .banner-title span {
    color: var(--dark-yellow-color);
}


.sustainability-page .sustainability-visual {
    margin-left: auto;
}

.sustainability-page .sustainability-inner {
    position: relative;
}

/* #endregion Point */


/*----------------------------------------------
1.2 Villas
----------------------------------------------*/

/* #region Point */


.label.label-one {
    left: 0;
    top: 50px;
}

.label.label-two {
    top: 20px;
    left: 50%;
}

.label.label-three {
    right: 0;
    top: 50px;
}

.label.label-four {
    left: 0;
    bottom: 0;
}

.label.label-five {
    right: 0;
    bottom: 0;
}

.label.label-one::before {
    content: "";
    position: absolute;
    left: 0;
    top: 43px;
    right: 0;
    margin: 0 auto;
    height: 100px;
    width: 2px;
    background: var(--green-wet);
}

.label.label-one::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 20px;
    height: 20px;
    background: var(--green-wet);
    border-radius: 50%;
    top: 143px;
    box-shadow: 0px 0px 30px #35FF3A;
}

.label.label-two::before {
    content: "";
    position: absolute;
    left: 0;
    top: 43px;
    right: 0;
    margin: 0 auto;
    height: 100px;
    width: 2px;
    background: #E7C16D;
}

.label.label-two::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 20px;
    height: 20px;
    background: #E7C16D;
    border-radius: 50%;
    top: 143px;
    box-shadow: 0px 0px 30px #E7C16D;
}

.label.label-three::before {
    content: "";
    position: absolute;
    left: 0;
    top: 43px;
    right: 0;
    margin: 0 auto;
    height: 100px;
    width: 2px;
    background: var(--green-wet);
}

.label.label-three::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 20px;
    height: 20px;
    background: var(--green-wet);
    border-radius: 50%;
    top: 143px;
    box-shadow: 0px 0px 30px #35FF3A;
}

.label.label-four::before {
    content: "";
    position: absolute;
    left: 0;
    top: -115px;
    right: 0;
    margin: 0 auto;
    height: 100px;
    width: 2px;
    background: #E7C16D;
}

.label.label-four::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 20px;
    height: 20px;
    background: #E7C16D;
    border-radius: 50%;
    top: -115px;
    box-shadow: 0px 0px 30px #E7C16D;
}

.label.label-five::before {
    content: "";
    position: absolute;
    left: 0;
    top: -115px;
    right: 0;
    margin: 0 auto;
    height: 100px;
    width: 2px;
    background: var(--green-wet);
}

.label.label-five::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 20px;
    height: 20px;
    background: var(--green-wet);
    border-radius: 50%;
    top: -115px;
    box-shadow: 0px 0px 30px #35FF3A;
}

/* #endregion Point */

/*----------------------------------------------

[Content Page]

2. Amenities

----------------------------------------------*/
.amenitie-tab {
    padding: 60px 0;
}

.amenitie-tab .tab-total-items {
    display: inline-block;
}

.amenitie-tab .amenitie-tab-link li button,
.world-experience .world-experience-tab ul li button {
    width: 100%;
    border-radius: 0px;
    padding: 22px 15px;
    margin: 10px 0;
    position: relative;
    background-color: var(--extra-light-yellow);
    box-shadow: 0px 27px 46px 0px rgba(236, 225, 204, 0.50);
}

.amenitie-tab .amenitie-tab-link li button.active,
.world-experience .world-experience-tab ul li button.active {
    background-color: var(--dark-yellow-color);
}

.amenitie-tab .amenitie-tab-link li button.active::before {
    content: "";
    position: absolute;
    width: 0px;
    height: 0px;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-left: 20px solid var(--dark-yellow-color);
    right: -29px;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 9;
}

.amenitie-tab .amenitie-tab-link li button.active img,
.world-experience .world-experience-tab ul li button.active img {
    -webkit-filter: brightness(0) invert(1);
    filter: brightness(0) invert(1);
}

.amenitie-tab .amenitie-tab-link li button span,
.world-experience .world-experience-tab ul li button span {
    display: block;
    text-transform: uppercase;
    color: var(--black-color);
    margin-top: 20px;
    font-size: 14px;
    letter-spacing: 0.5px;
}

.amenitie-card {
    margin-bottom: 20px;
}

.amenitie-card .amenitie-body {
    padding: 20px;
}

.amenitie-card .amenitie-body img {
    display: inline-block;
    margin-left: 20px;
}

.contact-form-inner {
    margin: 40px 0 100px;
}

/*----------------------------------------------

[Content Page]

3. Why us

----------------------------------------------*/

.architect {
    position: relative;
    padding: 50px 0;
}

.architect .architect-slider {
    width: 50%;
    margin-left: auto;
}

.architect-slider .carousel-indicators button {
    width: 6px;
    height: 6px;
    background-color: var(--white-color);
    border-radius: 50%;
    position: relative;
    margin: 0 8px;
}

.architect-slider .carousel-indicators button.active::before {
    content: "";
    width: 13px;
    height: 13px;
    border-radius: 50%;
    position: absolute;
    border: 2px solid var(--white-color);
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    left: 50%;
}

.architect-slider .carousel-item {
    position: relative;
}

.architect-carousel .carousel-item::before {
    position: absolute;
    content: "";
    left: 0px;
    width: 100%;
    bottom: 0;
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(217, 217, 217, 0)), to(var(--secondary-color)));
    background: -o-linear-gradient(top, rgba(217, 217, 217, 0) 0%, var(--secondary-color) 100%);
    background: linear-gradient(180deg, rgba(217, 217, 217, 0) 0%, var(--secondary-color) 100%);
    height: 420px;
}

.designers-carousel .carousel-item::before {
    position: absolute;
    content: "";
    left: 0px;
    width: 100%;
    bottom: 0;
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(217, 217, 217, 0)), to(#E7C16D));
    background: -o-linear-gradient(top, rgba(217, 217, 217, 0) 0%, #E7C16D 100%);
    background: linear-gradient(180deg, rgba(217, 217, 217, 0) 0%, #E7C16D 100%);
    height: 420px;
}

.architect .architect-panel {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
}

.interior-designers .architect-slider {
    margin-right: auto;
    margin-left: unset;
}

.architect .architect-panel {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
}

.architect-card-block {
    margin-top: 50px;
}

.architect-card-block .architect-card .architect-card-body {
    background-color: var(--dark-yellow-color);
    text-align: center;
    padding: 20px;
}

.experience {
    padding: 80px 0;
}

.experience .experience-description {
    margin: 25px 0;
}

.experience-visual img {
    width: auto;
    height: 100%;
}

.villa-quote {
    padding: 50px 0;
}

.villa-quote .quote-description {
    text-align: center;
}

.villa-quote .quote-description h2 span {
    color: var(--dark-yellow-color);
}

.villa-quote .quote-description>span {
    font-size: 20px;
}

.villa-special-card .villa-special-card-icon img {
    display: inline-block;
    margin-bottom: 30px;
}

.villa-special .comfort-description {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background: var(--body-color);
    border-top: 2px solid #E7C16D;
    -webkit-box-shadow: 0px 30px 50px rgba(236, 225, 204, 0.2);
    box-shadow: 0px 30px 50px rgba(236, 225, 204, 0.2);
    padding: 20px;
}

.villa-special .villa-special-card {
    max-width: 600px;
    margin: 0 auto;
}

.villa-special .comfort-block {
    margin: 50px 0;
}

.core-value {
    padding: 80px 0;
}

.vision-mission {
    padding: 80px 0;
}

.vision-mission-window {
    border-radius: 300px 300px 0 0;
    min-height: 670px;
    padding: 60px 50px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
}

.vision-mission-window img {
    position: absolute;
    top: 0;
}

.vision-mission-description {
    max-width: 375px;
}

.vision-mission-card {
    margin: 40px 0;
}

.vision {
    background-color: var(--dark-yellow-color);
}

.mission {
    background-color: var(--secondary-color);
    color: var(--white-color);
}

.mission h2 {
    color: var(--white-color);
}

.founder-media-object-visual .founder-about {
    text-align: center;
    padding: 50px 15px;
}

.management-card {
    margin-bottom: 20px;
}
.management-card .management-body {
    text-align: center;
    padding: 50px 15px;
}
.tie-up .form .form-input {
    background-color: var(--body-color);
    border: 1px solid var(--secondary-border);
    border-radius: 0px;
    height: 60px;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.tie-up .form textarea {
    background-color: var(--body-color);
    border: 1px solid var(--secondary-border);
    border-radius: 0px;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.tie-up {
    padding: 85px 0 70px;
}

.atmosphere-window-box {
    position: relative;
    max-width: 485px;
    margin: 0 auto;
}

.atmosphere-window {
    width: 485px;
    height: 636px;
    overflow: hidden;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 280px 280px 0 0;
    position: relative;
}

.atmosphere-window-box .atmosphere-window-border {
    width: 485px;
    height: 636px;
    border-radius: 280px 280px 0 0;
    content: "";
    position: absolute;
    right: -37px;
    bottom: -37px;
    background: rgba(231, 193, 109, 0.1);
    border: 2px solid var(--dark-yellow-color);
}

.customer-relation {
    position: relative;
    padding: 120px 0;
}

.customer-relation-visual img{
    max-width: 650px;
    width: 100%;
}

.customer-relation-panel {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
}

.contact-type {
    padding: 60px 0;
}

.contact-type-block {
    border-top: 1px solid var(--dark-yellow-color);
    border-bottom: 1px solid var(--dark-yellow-color);
    padding: 40px 0;
}

.contact-type-card {
    border-right: 1px solid var(--dark-yellow-color);
    height: 100%;
    max-width: 345px;
    margin: 0 auto;
    padding-left: 40px;
}
.contact-type-card .contact-type-body h4 {
    color: var(--dark-yellow-color);
    padding: 20px 0 10px;
    text-transform: uppercase;
    font-size: 18px;
    font-family: var(--dunbar-tall);
}

.contact-type-card .contact-type-body p {
    text-transform: uppercase;
    color: var(--black-shade-1);
    font-size: 14px;
    line-height: 1.6;
    margin: 5px 0 0;
}

.contact-type-card .contact-type-body p a {
    text-transform: uppercase;
    color: var(--black);
    text-decoration: none;
}

.contact-type .social-media h4 {
    color: var(--black-shade-1);
    text-transform: uppercase;
    margin-bottom: 30px;
    font-size: 18px;
    font-family: var(--dunbar-tall);
}
.phone-type-card .contact-type-body h4{
    color: var(--secondary-color);
}
.contact-type .social-media a {
    text-decoration: none;
    color: var(--dark-yellow-color);
}
.contact-type .social-media a:not(:last-child){
    margin-right: 35px;
}
.contact-type .social-media a:hover {
    color: #cda754;
}

.contact-type .social-media a i {
    font-size: 28px;
}

.map {
    padding: 60px 0;
}

.customer-fact {
    padding: 40px 0;
}

.fact-card {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-left: 2px solid var(--dark-yellow-color);
}

.fact-card .fact-icon {
    display: inline-block;
    margin-right: 30px;
}

.fact-card .fact-body h2 {
    margin-bottom: 0px;
}

.investment .property-card .property-card-body {
    text-align: center;
}

.investment .property-card .property-card-body p {
    color: var(--white-color);
}

.easy-investment-para {
    border-left: 2px solid var(--dark-yellow-color);
    padding: 20px;
    margin: 40px 0;
}

.easy-investment-para p {
    margin-bottom: 0;
}

.easy-investment-links {
    margin-top: 20px;
}

.easy-investment-links li {
    margin-right: 30px;
    float: left;
    width: 30%;
    position: relative;
    padding-left: 20px;
    margin-bottom: 15px;
}

.easy-investment-links li::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 12px;
    height: 12px;
    background-color: var(--dark-yellow-color);
    border-radius: 50%;
    left: 0;
    border: 3px solid var(--body-color);
    outline: 1px solid var(--dark-yellow-color);

}

.easy-investment-links li a {
    color: var(--black-color);
}

.easy-investment-links li:nth-child(even)::before {
    background-color: var(--secondary-color);
    border: 3px solid var(--body-color);
    outline: 1px solid var(--secondary-color);
}

/*-------- Changes ---------*/
.partner-with-us {
    background-image: url(../images/visuals/partners.jpg);
}

.why-us-visual {
    background-image: url(../images/visuals/creating-lifestyle.jpg);
}

.villa-visual {
    background-image: url(../images/visuals/villas.jpg);

}

.sustainability-visual,
.responsible-development {
    background-image: url(../images/visuals/responsible-development.jpg);
}

.invest-visual {
    background-image: url(../images/visuals/mini-wood-house.jpg);
}

.customerrelation-visual {
    background-image: url(../images/visuals/business-partners.jpg);
}

.map-url {
    margin-top: 40px;
}

.eco-friendly-shapes a {
    color: var(--white-color);
    display: inline-block;
    border-top: 1px solid var(--white-color);
    border-bottom: 1px solid var(--white-color);
}

.short-intro {
	margin-top: 150px;
	text-align: center;
	padding: 50px 0;
}

.short-intro h1 {
    color: var(--black-color);
}

.short-intro h1 span {
    color: var(--dark-yellow-color);
}

.slide-image {
    position: relative;
}

video {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
}

.navigation-menu .bar1,
.navigation-menu .bar2,
.navigation-menu .bar3 {
    width: 25px;
    height: 2px;
    background-color: var(--white-color);
    margin: 6px 0;
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
}

.navigation-menu .bar3 {
    width: 20px;
}

button.navbar-toggler {
    padding: 0;
    width: 50px;
    height: 50px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 50%;
}

/* .sticky button.navbar-toggler {
    background-color: var(--body-color);
    padding: 0;
    width: 50px;
    height: 50px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 50%;
    -webkit-box-shadow: 0px 0px 10px #0000001f;
    box-shadow: 0px 0px 10px #0000001f;
} */

/* .sticky .navigation-menu .bar1,
.sticky .navigation-menu .bar2,
.sticky .navigation-menu .bar3 {
    background-color: var(--black-color);
} */

.navbar-brand img {
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}

/* .sticky .navbar-brand img {
    opacity: 0;
} */
.wide-container {
    max-width: 1750px;
}

.sticky .top-bar {
    opacity: 0;
    display: none;
}

section.enquire-form {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    z-index: 99;
}

.enquire-form .contactus-form-description h2,
.enquire-form .contactus-form-description p,
.enquire-form .contactus-form-description label {
    color: var(--white-color);
}
.contactus-form .window-visual {
    max-width: 518px;
  }
section.enquire-form {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #333333f3;
    z-index: 99;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.contactus-form-description{
    padding-left: 95px;
}
.partners-contact-form{
    padding-right: 95px;
}
.contactus-form-description h2{
    color: var(--black-shade-1);
}
.enquire-form .form .form-input {
    background-color: transparent;
    border: 1px solid var(--secondary-border);
    border-radius: 0px;
    height: 60px;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #fff;
}

.enquire-form .form ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #fff;
}

.enquire-form .form textarea {
    background-color: transparent;
    border: 1px solid var(--secondary-border);
    border-radius: 0px;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.enquire-form .close {
    color: #ffffff;
    top: 27%;
    position: absolute;
    left: calc(100% - 600px);
    cursor: pointer;
}

.atmosphere-living-body .navigation-menu .bar1,
.atmosphere-living-body .navigation-menu .bar2,
.atmosphere-living-body .navigation-menu .bar3 {
    background-color: var(--black-color);
}

.offcanvas.enquire-canvas {
    height: 100%;
    background: #333333f2;
    width: 100%;
}

button.btn-close {
    margin-left: auto;
    -webkit-filter: brightness(0) invert(1);
    filter: brightness(0) invert(1);
}

.enquire-body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.lifestyle-home {
    padding: 50px 0;
}

.property-card {
    overflow: hidden;
    transition: 0.3s;
    cursor: pointer;
}

.property-card .property-card-visual img {
    transition: 0.3s;
}

.property-card .property-card-visual img:hover {
    transform: scale(1.09);
}

.main-banner .social-media a:hover {
    color: var(--dark-yellow-color);
}

.property .property-block .property-column:nth-child(even) .property-card .property-card-body span {
    color: #333333;
}

/*-- Media Gallery --*/
.world-experience-tab ul {
    gap: 22px;
}
.world-experience-tab ul li{
    width: 20%;
}
.world-experience-tab ul li:nth-child(even) .experice-tab-title{
    color: var(--secondary-color);
}
.world-experience .world-experience-tab ul li:nth-child(even) button.active .experice-tab-title{
    color: var(--bs-white);
}
.world-experience-tab ul li button{
    position: relative;
    border: 1px solid #ECE1CC;
}

.world-experience-tab ul li button.active::before {
	width: 0px;
	height: 0px;
	border-left: 18px solid transparent;
	border-right: 18px solid transparent;
	border-top: 20px solid var(--dark-yellow-color);
	content: "";
	position: absolute;
	bottom: -18px;
    left: calc(50% - 20px);
}

.slick{
    overflow: hidden;
}

.slick span {
    height: 100%;
    position: relative;
    transition: all .4s ease;
    overflow: hidden;
    display: flex;
    justify-content: center;
    border-radius: 200px 200px 0px 0px;
    width: 100%;
    transform: translate(0, 0) scale(1);
}

  .slick-slide.lt2 span {
    transform: translate(0, 0) scale(0.87);
    
  }
  .slick-slide.lt1 span {
    transform: translate(0, 0) scale(0.87);
  }
  .slick-slide.gt1 span {
    transform: translate(0, 0) scale(0.87);
  }
  .slick-slide.gt2 span {
    transform: translate(0, 0) scale(0.87);
  } 
  
  .slick-slide.slick-center span {
    z-index: 1;
    opacity: 1;
    color: #e67e22;
  }
  
  .slick-prev,
  .slick-next{
    position: absolute;
    top: 50%;
    appearance: none;
    margin-top: -10px;
    padding: 0;
    border: 0;
    background: none;
    cursor: pointer;
    color: transparent;
    outline: none;
    z-index: 100;
  }
  
  .slick-prev {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 10px 10px 0;
    border-color: transparent #333333 transparent transparent;
    left: 0;
  }
  .slick-next {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 0 10px 10px;
    border-color: transparent transparent transparent #333333;
    right: 0;
  }
  

/*-- Social --*/
.socialmedia-card {
	display: flex;
	justify-content: center;
    align-items: center;
    border-right: 1px solid #D2AE5F;
}

.socialmedia-card .social-media-body h5 {
	color: #D2AE5F;
}

.socialmedia-box:nth-child(even) .socialmedia-card .social-media-icon, .socialmedia-box:nth-child(even) .socialmedia-card .social-media-icon {
    border: 1px solid #0D8686;
    color: #0D8686;
}

.socialmedia-box:nth-child(even) .socialmedia-card .social-media-body h5 {
    color: #0D8686;
}

.socialmedia-box:last-child .socialmedia-card{
    border: 0;
}

.social-box {
	display: flex;
	align-items: center;
}

.social-box .social-icon {
	width: 48px;
	height: 48px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	margin-right: 20px;
	font-size: 24px;
    color: #ffffff;
}

.social-box .facebook{
    background-color: #3A559F;
    filter: drop-shadow(6px 6px 30px rgba(160, 177, 224, 0.6));
}

.social-box .instagram{
    background: #d6249f;
    background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
    filter: drop-shadow(6px 6px 30px rgba(218, 133, 159, 0.4));
}



/*-- Blog --*/

.blog {
    padding: 70px 0;
}
.blog-visual{
    background-image: url(../images/visuals/modern-house-with-garden.jpg);
}
.blog-box {
    border: 1px solid var(--secondary-border);
    padding: 30px;
    margin-bottom: 26px;
}
.blog-box h3{
    color: var(--black-shade-1);
    margin-bottom: 20px;
}
.search-box .atamosphere-input {
    font-size: 12px;
    padding-right: 55px;
}

.blog .search-box button {
    border: 0px;
    right: 0px;
    position: absolute !important;
    height: 100%;
    width: 60px;
    border-radius: 0;
    color: #D2AE5F;
    z-index: 9;
}
.category ul li a {
    color: var(--black-shade-1);
    padding-left: 0;
}
.category ul li a::before {
    content: "";
    height: 25px;
    background-color: var(--dark-yellow-color);
    display: flex;
}
.category ul li a.active::before{
    width: 3px;
}
.category ul li a:hover,
.category ul li a:focus,
.category ul li a.active {
    color: var(--dark-yellow-color);
}
.category ul li a:hover span,
.category ul li a:focus span,
.category ul li a.active span {
    padding-left: 8px;
}
.popular-post-card:not(:last-child) {
    margin-bottom: 38px;
}

.popular-post-card .popular-post-visual {
    width: 92px;
}
.popular-post-card .popular-post-body{
    width: calc(100% - 92px);
    padding-left: 18px;
}
.popular-post-card .popular-post-body a {
    color: var(--black-shade-1);
    font-family: var(--dunbar-tall);
    font-weight: normal;
}

.popular-post-card .popular-post-body a:hover {
    color: #D2AE5F;
}

.popular-post-card .popular-post-body span {
    color: var(--gray-500);
    font-size: 12px;
}

.btn-border {
    border: 1px solid var(--mute-border);
    color: var(--gray-500);
    letter-spacing: 0.5px;
    font-size: 13px;
}

.btn-border:hover {
    border-color: var(--dark-yellow-color);
    background-color: var(--dark-yellow-color) !important;
    color: var(--bs-white);
}

.blog-detail li:not(:last-child) {
    margin-right: 40px;
}
.blog-detail li img{
    vertical-align: sub;
    margin-right: 12px;
}
.blog-detail li span {
    font-size: 14px;
    color: var(--gray-500);
}
.blog-post .blog-post-visual {
    position: relative;
}

.blog-post .blog-post-visual .arrow-btn {
    top: 15px;
    right: 15px;
    width: 50px;
    height: 50px;
}

.blog-post .blog-post-body {
    padding: 20px 0 84px;
}

.blog-post .blog-post-body h2 {
    margin: 10px 0 30px 0;
    color: var(--black-shade-1);
    font-family: var(--dunbar-tall-book);
}
.popular-post-body a{
    display: block;
    font-weight: 600;
}
.blog-post .blog-post-body p{
    color: var(--gray-500);
    margin-bottom: 25px;
}
.pagination a {
	width: 47px;
	height: 47px;
	border: 1px solid var(--dark-yellow-color);
	color: var(--dark-yellow-color);
    font-size: 12.4px;
}
.pagination li:not(:last-child){
    margin-right: 10px;
}
.pagination a:hover, .pagination a.active {
    background-color: #D2AE5F;
    color: #ffffff;
}
.sustainability-slider-body p {
    margin-bottom: 0;
}
.main-slider .slider-bg button.slick-next {
    right: 0;
    left: 0;
    margin: 0 auto;
    bottom: 11px;
}
.main-slider .slider-bg button.slick-prev {
    right: 145px;
    left: 0;
    margin: 0 auto;
    bottom: 11px;
    z-index: 99;
}
.main-slider .slick-prev i,
.main-slider .slick-next i {
    font-size: 18px;
    color: var(--white-color);
}
.slider-card .slider-visual {
    overflow: hidden;
    border-radius: 250px 250px 0px 0px;
    position: relative;
    width: 401px;
}
.window-wrapper {
    display: flex;
    align-items: center;
    position: relative;
    justify-content: space-between;
    overflow: hidden;
}
.window-wrapper .window-border {
    height: 577px;
    border-radius: 280px 280px 0 0;
    content: "";
    position: absolute;
    right: 317px;
    bottom: -30px;
    background: rgba(231, 193, 109, 0.1);
    border: 2px solid var(--dark-yellow-color);
    width: 401px;
}
.window-wrapper .blur-window {
    width: 401px;
    height: 577px;
}
/* .window-wrapper .blur-wimdow {
    /* transform: scale(0.6) translateX(120px) !important; *
    -webkit-filter: blur(5px);
    -moz-filter: blur(5px);
    -o-filter: blur(5px);
    -ms-filter: blur(5px);
    filter: blur(3px);
} */
.progress {
    display: block;
    width: calc(100% - 120px);
    height: 2px;
    border-radius: 0;
    overflow: hidden;
    background-color: var(--white-color);
    background-image: linear-gradient(to right, var(--dark-yellow-color), var(--dark-yellow-color));
    background-repeat: no-repeat;
    background-size: 0 100%;
    transition: background-size .4s ease-in-out;
}
.main-slider .slider-progress {
    z-index: 9;
    width: calc(100% - 150px);
    padding: 0 40px;
}
.slider-progress #slider-counter {
    font-size: 60px;
    font-family: 'Vintage Glamour';
}
.welcome-text {
    position: absolute;
    top: 49%;
    left: 43%;
    transform: translate(-50%, -50%);
    right: 0;
    margin: 0 auto;
    display: inline-block;
    border-left: 3px solid var(--dark-yellow-color);
    padding-left: 82px;
    padding-top: 20px;
    padding-bottom: 20px;
}
.welcome-text>span {
    color: var(--white-color);
    text-transform: uppercase;
    display: inline-block;
    margin-bottom: 30px;
    font-size: 20px;
    letter-spacing: 3.4px;
}
.welcome-text h1 {
    letter-spacing: 2px;
    color: var(--white-color);
    font-style: italic;
}
.welcome-text h1 span {
    color: var(--dark-yellow-color);
}
.main-slider>button.slick-prev {
    display: none !important;
}
.main-slider>button.slick-next {
    right: 0;
    left: 0;
    margin: 0 auto;
    bottom: 38px;
}
.welcome-slide {
    z-index: 999;
}
.welcome-slide::before,
.main-slider .slider-content::before,
.banner-inner::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: linear-gradient(180deg, #000 0%, rgba(0, 0, 0, 0.00) 58.85%);
}
.main-slider .slider-content::before{
    z-index: 1;
}
.slider-btn {
    width: 66px;
    height: 66px;
    border: 1px solid var(--white-color);
    font-size: 18px;
    position: relative;
}
.slider-btn:hover {
    border: 1px solid var(--white-color);
    color: var(--white-color) !important;
}
.explore-now .slider-btn::before{
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: inherit;
    border-radius: 50%;
    animation: circle-ripple 2s ease-in-out infinite 0s;
    background-color: rgba(255,255,255,0.5);
}
@keyframes circle-ripple {
    0% {
      opacity: 1;
      transform: scale(0);
    }
    100% {
      opacity: 0;
      transform: scale(1.6);
    }
  }
/* new Images css */
.back-window {
    top: 0;
    right: 0;
    position: absolute;
}
.front-window{
    position: absolute;
    bottom: 0;
    top: 0;
    margin: auto;
    display: flex;
    align-items: center;
    transform: scale(0.72) translateX(1080px);
    -o-transform: scale(0.72) translateX(1080px);
    -ms-transform: scale(0.72) translateX(1080px);
    -moz-transform: scale(0.72) translateX(1080px);
    -webkit-transform: scale(0.72) translateX(1080px);
    -webkit-filter: blur(5px);
    -moz-filter: blur(5px);
    -o-filter: blur(5px);
    -ms-filter: blur(5px);
    filter: blur(3px);
}
.active-slide{
    -webkit-filter: blur(0);
    -moz-filter: blur(0);
    -o-filter: blur(0);
    -ms-filter: blur(0);
    filter: blur(0);
    /* transform: scale(1) translateX(0) !important;
    -o-transform: scale(1) translateX(0) !important;
    -ms-transform: scale(1) translateX(0) !important;
    -moz-transform: scale(1) translateX(0) !important;
    -webkit-transform: scale(1) translateX(0) !important; */
}
.demo-slide{
    -webkit-filter: blur(0);
    -moz-filter: blur(0);
    -o-filter: blur(0);
    -ms-filter: blur(0);
    filter: blur(0);
    visibility: hidden;
    transform: none !important;
    -o-transform: none !important;
    -ms-transform: none !important;
    -moz-transform: none !important;
    -webkit-transform: none !important;
}
.back-window .blur-border {
    right: -33px;
}
.first-visual {
    z-index: 1;
}
.silder-text-hide {
    display: none;
}
/* features */
.feature-wrapper .nav-pills{
    margin-bottom: 0;
}
.feature-wrapper .nav-pills .nav-item .nav-link {
    padding: 28px 25px;
    color: var(--black-shade-1);
    background-color: var(--extra-light-yellow);
    border-top: 1px solid var(--dark-yellow-color);
    border-bottom: 1px solid var(--dark-yellow-color);
    font-family: var(--dunbar-tall);
    font-size: 20px;
    overflow: hidden;
}
.feature-wrapper .nav-pills .nav-item:not(:last-child) .nav-link{
    border-right: 1px solid var(--dark-yellow-color);
}
.feature-wrapper .nav-pills .nav-item .nav-link:hover,
.feature-wrapper .nav-pills .nav-item .nav-link:focus{
    background-color: var(--light-yellow);
}
.feature-wrapper .nav-pills .nav-item .nav-link.active {
    color: var(--black-shade-1);
    background-color: var(--primary-color);
    filter: drop-shadow(30px 30px 40px rgba(210, 196, 169, 0.60));
}
.feature-wrapper .nav-pills .nav-item .nav-link.active img{
    filter:grayscale(100%) contrast(0%) brightness(0.6);
}
.feature-inner-wrapper {
    padding: 100px 0 0;
    background-color: var(--light-yellow);
}
.feature-inner-wrapper::after{
    content: "";
    background-color: var(--body-color);
    height: 90px;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
}
.small-heading {
    display: flex; 
    margin-bottom: 20px;
    align-items: center;
}
.small-heading hr{
    width: 139px;
    margin-right: 15px;
    background-color: var(--black-shade-1);
}
.small-heading span{
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 2px;
}
.yellow-text {
    color: var(--dark-yellow-color);
}
.secondary-heading {
    margin-bottom: 30px;
}
.secondary-heading h2{
    letter-spacing: 0.8px;
}
.feature-tab-pane .feature-text .feature-body p{
    max-width: 575px;
    margin-left: 0;
    margin-right: 0;
}
.btn.theme-btn {
    padding: 10px 25.05px;
    background-color: var(--dark-yellow-color);
    font-size: 12px;
    letter-spacing: 2px;
    font-family: var(--secondary-font);
}
.btn.md-btn{
    font-size: 14px;
    letter-spacing: 0;
    padding-top: 13.5px;
    padding-bottom: 13.5px;
}
.features-window {
    position: relative;
}
.window-visual {
    max-width: 544.63px;
}
.window-visual span{
    border-radius: 333px 333px 0px 0px;
}
.atamosphere-image-slide{
    width: calc(100% - 385px);
}
.atamosphere-image-slide .active-slide .window-visual{
    max-width: 441px;
}
.atamosphere-image-slide .window-visual {
    /* max-width: 300px; */
}
.window-visual::before{
    border-radius: 333px 333px 0 0;
    content: "";
    position: absolute;
    right: 0px;
    bottom: 0;
    /* background: rgba(231, 193, 109, 0.1); */
    border: 2px solid var(--dark-yellow-color);
    width: calc(100% - 16px);
    height: calc(100% - 16px);
}
.slider-card{
    padding-left: 200px;
}
.atamosphere-left-text {
    max-width: 385px;
}
.feature-window {
    position: relative; 
}
.features-inner-slider {
    margin-top: -313px;
    max-width: 1085px;
    gap: 19.8px;
}
.features-inner-slider .feature-card {
    padding: 53px 20px 19px;
    border-radius: 500px 500px 0 0;
    background-color: var(--extra-light-yellow);
    box-shadow: 2.15px 3.15px 8.21px rgba(168, 136, 75, 0.20);
}
.features-inner-slider .feature-body{
    text-align: center;
    margin-top: 29px;
}
.yellow-border {
    border-bottom: 2px solid var(--dark-yellow-color);
}
.green-border {
    border-bottom: 2px solid var(--secondary-color);  
}
.feature-body p {
    margin: 0 auto 25px;
}
.features-inner-slider .feature-body p {
    line-height: 1.7;
    font-size: 14px;
    max-width: 181px;
    min-height: 72px;
}
.feature-body span {
    display: inline-block;
    margin-bottom: 15px;
    font-family: var(--dunbar-tall);
    letter-spacing: 0.65px;
}
.feature-card-icon {
    min-height: 50px;
}
/* slider arrow */
.btn-yellow-arrow {
    position: absolute;
    top: 50%; 
    display: block; 
    width: 20px; 
    height: 20px; 
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    color: var(--dark-yellow-color);
    border: none;
    outline: none;
    background: transparent;
}
.yellow-prev {
    left: -35px;
}
.yellow-next {
    right: -35px;
}
/* our properties */
.our-properites {
    padding: 150px 0 90px;
}
.properties-heading {
    margin-bottom: 55px;
}
.properties-heading h2{
    font-family: 'DunbarTall';
}
.properties-tabs .nav-item .nav-link  {
    font-family: 'DUnbarTall';
}
.properties-slider-wrapper {
    position: relative;
    padding-left: 100px;
}
.our-properites .nav-pills .nav-item:first-child .nav-link{
    border-left: 1px solid var(--dark-yellow-color);
}
.our-properites .nav-pills .nav-item:last-child .nav-link{
    border-right: 1px solid var(--dark-yellow-color);
}
.our-properites .nav-pills .nav-item .nav-link{
    padding: 20px 25px;
}
.properties-container{
    position: relative;
    padding-left: 16.2%;
}
.properties-slider-inner {
    margin-right: -470px;
    gap: 24px;
    position: relative;
    min-height: 450px;
}
.single-property{
    width: 424px;
    height: 100%;
    border: 1px var(--border-color) solid;
    box-shadow: 30px 30px 40px 0px rgba(236, 225, 204, 0.25);
    background-color: var(--extra-light-yellow);
    position: absolute;
    left: 0;
    transform: translateX(895px);
    -o-transform: translateX(895px);
    -ms-transform: translateX(895px);
    -moz-transform: translateX(895px);
    -webkit-transform: translateX(895px);
}
.single-property + .single-property + .single-property{
    transform: translateX(1345px);
    -o-transform: translateX(1345px);
    -ms-transform: translateX(1345px);
    -moz-transform: translateX(1345px);
    -webkit-transform: translateX(1345px);
}
.fixed-position-card {
    position: absolute;
    top: 0;
    height: 100%;
    transform: translateX(1345px);
    -o-transform: translateX(1345px);
    -ms-transform: translateX(1345px);
    -moz-transform: translateX(1345px);
    -webkit-transform: translateX(1345px);
}
.property-img {
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    clip: rect(78px, 327px, 340px, 95px);
    overflow: hidden;
  }
.property-active-slide .property-img{
    clip: auto;
}
.property-active-slide .property-img img{
    object-fit: cover;
    height: 100%;
}
.property-img img {
    width: auto;
    height: 320px;
    max-width: initial;
  }
.single-property .property-caption h2{
    font-size: 16px;
    color: var(--black-shade-1);
    margin-top: 28px;
    margin-bottom: 0;
    text-align: center;
}
.single-property.property-active-slide .property-caption h2{
    color: var(--bs-white);
    font-size: 40px;
    text-align: left;
}
.property-caption .text-btn{
    border-top: 1px var(--dark-yellow-color) solid;
    border-bottom: 1px var(--dark-yellow-color) solid;
    font-size: 14px;
    display: none;
    padding:0 ;
    margin-top: 25px;
}
.property-active-slide .property-caption .text-btn{
    color: var(--bs-white);
    height: auto;
    padding: 10px 0;
    display: inline-block;
}
.single-property.property-active-slide{
    padding: 0;
    width: 870px;
    border-color: transparent;
    box-shadow: none;
    transform: translateX(0);
    -o-transform: translateX(0);
    -ms-transform: translateX(0);
    -moz-transform: translateX(0);
    -webkit-transform: translateX(0);
}
.property-caption {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 0 40px 35px;
}
.property-active-slide .property-caption{
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
}
.next-prev-arrows a{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px var(--dark-yellow-color) solid;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--dark-yellow-color);
    font-size: 27px;
    margin-right: 15px;
}
.next-prev-arrows a:hover,
.next-prev-arrows a:focus{
    background-color: var(--dark-yellow-color);
    color: var(--black);
}
.slider-progress-bar li{
    width: 100%;    
    height: 1px;
    background-color: var(--dark-yellow-color);
    position: relative;
}
.slider-progress-bar li::before{
    content: "";
    position: absolute;
    left: 0;
    top: -2px;
    height: 5px;
}
.slider-progress-bar li.active::before{
    width: 100%;
    background-color: var(--dark-yellow-color);
}
.slider-progress-bar{
    width: calc(100% - 130px);
    padding-left: 12px;
    max-width: 695px;
}
.slider-numbering{
    padding-left: 15px;
}
.slider-numbering span{
    font-size: 24px;
    color: var(--dark-yellow-color);
    font-family: var(--primary-font);
}
/* intuitive luxury */
.intutive-luxury {
    padding: 176px 0 115px;
    background-image: url("../images/visuals/intuitive-luxury-haritage-bg.png");
    background-repeat:  no-repeat;
    background-position: center bottom;
    background-size: 100%;
    background-color: var(--body-color);
}
.intutive-luxury .features-window{
    padding-bottom: 40px;
}
.brand-heritage-image {
    padding-left: 142px;
}
.section-space {
    margin-top: 30px;
}
.intutive-luxury .window-visual{
    max-width: 417px;
}
.intutive-luxury .window-large-visual{
    margin-left: 75px;
    padding-bottom: 40px;
}
.intutive-luxury .window-thumb-visual{
    max-width: 221px;
}
.brand-heritage-window .window-large-visual{
    margin-right: 40px;
}
/* intuitive-tech */
.intuitive-tech {
    padding-top: 90px;
    background-color: var(--medium-yellow);
}
.intuitive-card-body span {
    font-size: 20px;
    margin: 15px 0;
    display: inline-block;
    font-family: 'DunbarTall';
    transition: all 400ms ease-in-out 0s;
    -o-transition: all 400ms ease-in-out 0s;
    -ms-transition: all 400ms ease-in-out 0s;
    -moz-transition: all 400ms ease-in-out 0s;
    -webkit-transition: all 400ms ease-in-out 0s;
}
.intuitive-card:hover .intuitive-card-body span {
    transform: translateX(10px);
    -o-transform: translateX(10px);
    -ms-transform: translateX(10px);
    -moz-transform: translateX(10px);
    -webkit-transform: translateX(10px);
}
.smart-tech-tabs .nav-link {
    max-width: 300px;
    display: flex;
    margin: 35px 0;
    min-height: 180px;
}
.left-side-tabs{
    padding-right: 75px;
}
.right-side-tabs{
    padding-left: 75px;
}
.btn.yellow-theme-btn{
    background-color: var(--dark-yellow-color);
}
.btn.yellow-theme-btn:hover,
.btn.yellow-theme-btn:focus,
.btn.yellow-theme-btn:active,
.btn.yellow-theme-btn:visited  {
    background-color: var(--dark-yellow-color);
}
.yellow-btn-wrap:hover img,
.yellow-btn-wrap:focus img,
.yellow-btn-wrap:active img,
.yellow-btn-wrap:visited img{
    filter: invert(51%) sepia(567%) saturate(308%) hue-rotate(3deg) brightness(124%) contrast(86%);
}
.yellow-btn-wrap img{
    filter: invert(51%) sepia(67%) saturate(308%) hue-rotate(3deg) brightness(104%) contrast(86%);
}
/* sustainability */
.sustainability {
    padding: 90px 0;
}
.green-text {
    color: var(--green-color);
}

.btn.green-theme-btn {
    background-color: var(--green-color);
}
.btn.green-theme-btn:hover,
.btn.green-theme-btn:focus,
.btn.green-theme-btn:active,
.btn.green-theme-btn:visited  {
    background-color: var(--secondary-color);
}
.green-btn-wrap:hover img,
.green-btn-wrap:focus img,
.green-btn-wrap:active img,
.green-btn-wrap:visited img{
    filter: invert(34%) sepia(60%) saturate(1915%) hue-rotate(153deg) brightness(68%) contrast(90%);
}
.sustainability-body p {
    padding-right: 136px;
}

.sustainability-slider {
    width: 957px;
    margin-left: 39px;
    margin-top: -255px;
}
.responsible-card-slides {
    margin-top: -300px;
}
.sustainability-card {
    padding: 30px;
    text-align: center;
    border-top: 1px solid var(--border-color);
    border-left: 1px solid var(--border-color);
    border-right: 1px solid var(--border-color);
    background-color: var(--extra-light-yellow);
}
.sustainability-card.slick-active{
    box-shadow: 10px 10px 30px 0px rgba(173, 148, 92, 0.20);
}
.responsible-cards .sustainability-card:nth-child(odd){
    border-bottom: 2px var(--green-color) solid;
}
.responsible-cards .sustainability-card:nth-child(even){
    border-bottom: 2px var(--secondary-color) solid;
}
.sustainability-visual img {
    width: 100%;
}
.sustainability-slider-body span {
    margin: 15px 0;
    font-size: 20px;
    display: inline-block;
    font-family: 'DunbarTall';
}
.sustainability-slider .slick-prev:before, .slick-next:before {
    color: var(--green-color);
}
.responsible-cards{
    gap: 0 30px;
    max-width: 975px;
}
.slider-dots{
    margin-left: auto;
    width: auto;
    margin-right: 0;
    left: initial;
    right: 52px;
    bottom: 55px;
    margin-bottom: 0;
}
.slider-dots [data-bs-target]{
    width: 5px;
    height: 5px;
    border-radius: 50%;
    margin: 0 5px;
    transition: all 400ms ease-in-out 0s;
    -o-transition: all 400ms ease-in-out 0s;
    -ms-transition: all 400ms ease-in-out 0s;
    -moz-transition: all 400ms ease-in-out 0s;
    -webkit-transition: all 400ms ease-in-out 0s;
}
.slider-dots .active{
    width: 13px;
    height: 13px;
}
.intuitive-card-body span{
    color: var(--black-shade-1);
}
.intuitive-tech .nav .nav-link::after{
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 2px;
    height: 0;
    background-color: var(--secondary-color);
    transition: all 400ms ease-in-out 0s;
    -o-transition: all 400ms ease-in-out 0s;
    -ms-transition: all 400ms ease-in-out 0s;
    -moz-transition: all 400ms ease-in-out 0s;
    -webkit-transition: all 400ms ease-in-out 0s;
}
.intuitive-tech .nav .nav-link.active::after{
    height: 70px;
}
.intuitive-tech .left-side-tabs .nav-link:last-child::after,
.intuitive-tech .right-side-tabs .nav-link:first-child::after{
    background-color: var(--dark-yellow-color);
}
.iphone-frame {
    max-width: 378px;
    box-shadow: 10px 20px 20px #C1AF8A;
    border-radius: 50px 50px 0 0;
}
.iphone-ui{
    max-width: 360px;
    border-radius: 50px;
}
.app-ui-design img{
    width: 100%;
    margin-left: 2px;
}
.smart-slide-img{
    max-width: 360px;
    height: 450px;
    border-radius: 50px 50px 0 0;
    overflow: hidden;
}
.slider-inner-wrap::before{
    content: "";
    width: 382px;
    margin: auto;
    left: 0;
    right: 0;
    position: absolute;
    height: 450px;
    background-image: url("../images/icon/iphone-frame.svg");
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 100%;
    z-index: 1;
}
.smart-slide-caption{
    background-color: var(--extra-light-yellow);
}
.smart-slide-caption .intuitive-card{
    padding: 25px 0;
    border-bottom: 1px var(--border-color) solid;
}
.smart-slide-caption .intuitive-card-icon{  
    width: 50px;
}
.smart-slide-caption .intuitive-card-body{  
    width: calc(100% -50px);
}
.smartech-mobile-slider .carousel-inner-content-none::before{
    content: none;
}

/* intuitive-living */
.intuitive-living{
    padding: 80px 0 150px;
}
.download-brochure-action img{
    filter: none !important;
    width: 16px;
    margin-left: 10px;
}
.intuitive-living-accordion{
    max-width: 290px;
}
.intuitive-living-accordion .accordion-item,
.intuitive-living-accordion .accordion-button{
    border: 0;
    background-color: transparent;
}
.intuitive-living-accordion .accordion-item{
    border-bottom: 1px var(--border-color) solid;
}
.intuitive-living-accordion .accordion-item:first-child{
    border-top: 1px var(--border-color) solid;
}
.intuitive-living-accordion .accordion-button{
    padding: 21px 0;
    border: 0;
    box-shadow: none;
    font-size: 20px;
}
.intuitive-living-accordion .accordion-button::after,
.intuitive-living-accordion .accordion-button::before{
    width: 11px;
    height: 1px;
    background-color: var(--black-shade-1);
    background-image: none;
    content: "";
}
.intuitive-living-accordion .accordion-button::before{
    position: absolute;
    right: 0px;
    top: calc(50% + 0);
    transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
}
.intuitive-living-accordion .accordion-button[aria-expanded="true"]::before{
    opacity: 0;
}
.intuitive-living-accordion .accordion-button[aria-expanded="true"]{
    color: var(--dark-yellow-color);
}
.intuitive-living-accordion .accordion-body{
    padding: 0;
}
.slider-mobile-arrow{
    top: calc(50% - 15%);
    bottom: initial;
}
.slider-mobile-arrow .carousel-control-prev,
.slider-mobile-arrow .carousel-control-next{
    width: 40px;
    height: 40px;
    border: 2px var(--dark-yellow-color) solid;
    background-color: var(--light-cream);
    position: relative;
}
.slider-mobile-arrow img{
    max-width: 9px;
}
.slider-mobile-arrow .carousel-control-prev{
    left: -15px;
}
.slider-mobile-arrow .carousel-control-next{
    right: -15px;
}

/* Welcome Our Curiosity */
.welcome-our-curiosity{
    background-color: var(--primary-color);
    padding: 95px 0;
}
.welcome-our-curiosity .heading h2{
    color: var(--black-shade-1);
    margin: auto;
    max-width: 650px;
}
.curiosity-form{
    gap: 25px;
    max-width: 1130px;
}
.curiosity-form .form-control{
    background-color: rgba(255,255,255,0.3);
    padding: 18px 25px;
    box-shadow: 0px 4px 20px 0px #DEB353;
}
.form-submit .btn.theme-btn{
    background-color: var(--black-shade-1);
    padding: 17px 100px;
    font-size: 14px;
    letter-spacing: 0;
}
.form-submit .btn.theme-btn:hover,
.form-submit .btn.theme-btn:focus,
.form-submit .btn.theme-btn:active,
.form-submit .btn.theme-btn:visited{
    background-color: var(--secondary-color);
}
.form-checkbox input[type="checkbox"]{
    position: absolute;
    left: -9999px;
    top: 0;
}
.check-term{
    width: 16px;
    min-width: 16px;
    height: 16px;
    border-radius: 50px;
    border: 2px var(--black-shade-1) solid;
    margin-right: 11px;
    position: relative;
}
.check-term::before{
    content: "";
    position: absolute;
    left: 2px;
    top: 2px;
    width: 8px;
    height: 8px;
    background-color: var(--black-shade-1);
    border-radius: 20px;
    transform: scale(0);
    -o-transform: scale(0);
    -ms-transform: scale(0);
    -moz-transform: scale(0);
    -webkit-transform: scale(0);
}
.form-checkbox input[type="checkbox"]:checked + .check-term::before{
    transform: scale(1);
    -o-transform: scale(1);
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
}
.form-checkbox a{
    color: var(--black-shade-1);
}
.form-checkbox span {
    width: calc(100% - 16px);
  }

/* Footer */
footer{
    background-image: url(../images/visuals/footer-bg.png);
    background-repeat: no-repeat;
    background-position: 0 bottom;
    background-size: 100%;
}
footer .newsletter{
    margin: 60px 0 100px;
    border-bottom: 1px var(--border-color) solid;
    padding-bottom: 45px;
}
.newsletter-input{
    max-width: 490px;
}
footer .newsletter span,
.footer-heading span{
    font-size: 24px;
    font-family: 'DunbarTall';
}
footer .newsletter .form-control{
    border-color: var(--border-color);
    background-color: var(--light-yellow);
    padding: 17px 25px;
}
.btn.subscribe-btn{
    background-color: var(--dark-yellow-color);
    margin-left: 13px !important;
    padding-left: 29.5px;
    padding-right: 29.5px;
}
.btn.subscribe-btn:hover,
.btn.subscribe-btn:focus,
.btn.subscribe-btn:active,
.btn.subscribe-btn:visited{
    background-color: var(--dark-yellow-color);
}
.footer-heading span{
    color: var(--secondary-color);
}
.address p,
.address p a{
    display: flex;
    align-items: flex-start;
    color: var(--light-gray);
    font-weight: 300;
    font-family: var(--secondary-font);
}
.address p,
.address p a{
    display: flex;
    align-items: flex-start;
}
.address p span:first-child{
    width: 40px;
}
.address p span:last-child,
.address-view{
    width: calc(100% - 40px);
}
.address p span i{
    color: var(--secondary-color);
}
footer .short-intro {
    max-width: 465px;
}
footer .social-media li{
    position: relative;
}
footer .social-media{
    display: none;
}
footer .social-media li:not(:last-child){
    margin-right: 40px;
}
footer .social-media a{
    width: 40px;
    height: 40px;
    border: 1px var(--dark-yellow-color) solid;
    background-color: var(--light-cream);
    color: var(--secondary-color);
    transition: all 400ms ease-in-out 0s;
    -o-transition: all 400ms ease-in-out 0s;
    -ms-transition: all 400ms ease-in-out 0s;
    -moz-transition: all 400ms ease-in-out 0s;
    -webkit-transition: all 400ms ease-in-out 0s;
}
footer .social-media li:not(:last-child)::before{
    content: "";
    position: absolute;
    left: 60px;
    top: 12px;
    width: 1px;
    height: 16px;
    background-color: var(--gray-border);
}
footer .social-media a:hover,
.social-media a:focus{
    background-color: var(--dark-yellow-color);
}
.footer-links .footer-heading{
    text-align: right;
}
.footer-links a{
    color: var(--light-gray);
    font-weight: 300;
    font-family: var(--secondary-font);
    text-align: right;
    padding: 7px 0;
    transition: all 400ms ease-in-out 0s;
    -o-transition: all 400ms ease-in-out 0s;
    -ms-transition: all 400ms ease-in-out 0s;
    -moz-transition: all 400ms ease-in-out 0s;
    -webkit-transition: all 400ms ease-in-out 0s;
}
.footer-links a:hover,
.footer-links a:focus{
    color: var(--secondary-color);
}
.copyright{
    margin: 70px 0 0;
    border-top: 1px var(--border-color) solid;
    padding: 25px 0;
}
.copyright-text{
    font-weight: 300;
    color: var(--light-gray);
}
.privacy a{
    font-weight: 300;
    color: var(--light-gray);
}
.privacy a:not(:last-child){
    margin-right: 35px;
}
#scrolltop{
    position: fixed;
    right: 25px;
    bottom: 25px;
    width: 50px;
    height: 50px;
    background-color: var(--primary-color);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--black-shade-1);
    font-size: 27px;
    z-index: 999999999;
    transition: all 400ms ease-in-out 0s;
    -o-transition: all 400ms ease-in-out 0s;
    -ms-transition: all 400ms ease-in-out 0s;
    -moz-transition: all 400ms ease-in-out 0s;
    -webkit-transition: all 400ms ease-in-out 0s;
}
#scrolltop:hover,
#scrolltop:focus{
    background-color: var(--secondary-color);
    color: var(--bs-white);
}
.bg-image-none{
	background-image: none !important;
}
.contactus-form-radio .check-term{
    border-color: var(--dark-yellow-color);
}
.contactus-form-radio .form-checkbox input[type="checkbox"]:checked + .check-term::before{
    background-color: var(--dark-yellow-color);
}
.contactus-form-radio .form-checkbox span,
.contact-type-block li:last-child h4{
    color: var(--black-shade-1);
}
.contactus-form-input .btn-yellow{
    font-size: 14px;
    padding: 13.5px 15px;
}
.contact-des p,.accordion-body p{
    color: var(--gray-500);
}
.contact-type-icon img{
    height: 34px;
}
.phone-type-card,
.email-type-card{
    padding-left: 75px;
}
.contact-card-icon img{
    height: 34px;
}
.contact-card-body h4{
    font-size: 18px;
    padding: 20px 0 10px;
    color: var(--dark-yellow-color);
    font-family: var(--dunbar-tall);
    text-transform: uppercase;
}
.contact-card-body p,
.contact-card-body a{
    text-transform: uppercase;
    color: var(--black-shade-1);
    font-size: 14px;
    line-height: 1.6;
    margin: 5px 0 0;
    min-height: 60px;
}
.contact-type-block li{
    padding: 0 35px;
    width: 33.33%;
}
.contact-type-block li:not(:last-child){
    border-right: 1px var(--dark-yellow-color) solid;
}
.contact-type-block li:nth-child(2) h4{
    color: var(--secondary-color);
}
/* Gallery slides */
.media-gallery-slides{
    padding: 120px 0 70px;
}
.media-gallery-wrap ul{
    gap: 12px;
}
.gallery-img{
    border-radius: 800px 800px 0 0;
}
.media-gallery-wrap li,
.gallery-img img{
    width: 100%;
}
.media-gallery-wrap li:nth-child(4n+1) {
    width: calc(100% - 35%); 
}
.media-gallery-wrap li:nth-child(2n+2) {
    width: calc(100% - 15%);
}
.experience-img-wrap{
    min-height: 28px;
}

/* Our Board Page */
.founder-info{
    padding: 100px 0;
}
.founder-wrap{
    width: 100%;
    max-width: 342px;
}
.about-founder-text {
    padding-left: 100px;
}
.founder-wrap .window-visual::before{
    border-color: var(--dark-yellow-color);
    background-color: transparent;
}
.founder-wrap .window-visual img{
    width: 100%;
}
.salil .about-founder-text{
    padding-left: 0;
    padding-right: 100px; 
}
.founder-about{
    background-color: var(--dark-yellow-color);
    padding: 15px 20px;
}
.founder-about h3,
.founder-about span{
    font-size: 20px;
    color: var(--black-shade-1);
}
.founder-about span{
    font-size: 16px;
    margin-top: 5px;
    display: inline-block;
    text-transform: capitalize;
}
.founder-message span{
    color: var(--dark-yellow-color);
}
.about-founder-text p{
    color: var(--gray-500);
    /* margin: 25px 0 50px; */
    margin: 35px 0 0px;
}
.about-founder-text h2{
    color: var(--black-shade-1);
    text-transform: capitalize;
}
.reverse-img .founder-about{
    background-color: var(--secondary-color);
}
.reverse-img .founder-about *{
    color: var(--bs-white);
}
.management{
    padding: 70px 0 ;
}
.management .founder-about h3{
    color: var(--black-shade-1);
}
.dark-green-bg{
    background-color: var(--secondary-color);
}
.dark-green-bg *{
    color: var(--bs-white) !important;
}

/* Responsible Developement */
.sustainability-points .heading-type-two h2,
.villas .heading-type-two h2{
    color: var(--black-shade-1);
}
.sustainability-description{
    gap: 23px;
    padding-right: 50px;
}
.sustainability-point-card {
    border: 1px solid #CCCCCC;
    border-radius: 4px;
    padding: 30px 45px;
}
.sustainability-points .sustainability-body p{
    max-width: 560px;
    color: var(--gray-500);
    padding-right: 0;
}
.sustainability-point-card .point-card-icon {
    width: 108px;
    border-right: 1px var(--green-color) solid;
}
.sustainability-point-card .point-card-icon img{
    height: 50px;
    width: auto;
}
.sustainability-point-card .point-card-body{
    width: calc(100% - 108px);
    padding-left: 40px;
}
.sustainability-point-card .point-card-body h5{
    font-family: var(--dunbar-tall);
    color: var(--black-shade-1);
    margin: 0 0 8px;
}
.sustainability-point-card .point-card-body p{
    color: var(--gray-500);
    margin: 0;
}

/* villas Section */
.villas {
    padding: 60px 0;
}
.villas .heading-type-two h2 span {
    color: var(--secondary-color);
}
.villa-inner-visual {
    position: relative;
    padding: 100px 0 25px;
    margin-top: 60px;
}
.villa-inner-visual .label {
    position: absolute;
    display: inline-block;
}
.villa-panel{
    gap: 22px;
}
.villa-panel li:nth-child(even) .villa-card,
.right-villa-panel li:nth-child(odd) .villa-card{
    background-color: var(--green-wet);
}
.villa-panel .villa-card {
    width: 158px;
    height: 158px;
    padding: 35px 10px;
    border-radius: 0 40% 0 40%;
    background-color: var(--secondary-color);
}
.villa-panel li:nth-child(3) .villa-card,
.right-villa-panel .villa-card{
    border-radius: 40% 0 40% 0;
}
.villa-panel .villa-card-body {
    margin-top: 15px;
}
.villa-card-icon img{
    height: 40px;
    width: auto;
}
.right-villa-panel li:nth-child(even) .villa-card{
    background-color: var(--secondary-color);
}
.right-villa-panel li:nth-child(3) .villa-card{
    border-radius: 0 40% 0 40%;
}
.villa-inner-visual ul{
    max-width: 565px;
    padding: 0px 0 0 48px;
}
.villa-inner-visual ul li:first-child{
    margin-top: 42px;
    padding-left: 12px;
}
.villa-inner-visual ul li:nth-child(2){
    padding-left: 8px;
}
.villa-inner-visual ul li:nth-child(3){
    margin-top: 82px;
}
.label-bottom-lines{
    height: 145px;
}
.address-country{
    font-size: 18px;
    font-family: var(--dunbar-tall);
    color: var(--black-shade-1);
    margin-bottom: 5px;
}
.label-bottom-lines::before,
.label-bottom-lines::after{
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    margin: 0 auto;
    width: 2px;
    height: 100%;
    background: var(--green-wet);
}
.label-bottom-lines::after{
    width: 15px;
    height: 15px;
    border-radius: 50%;
    filter: drop-shadow(0px 0px 23.233078002929688px #35FF3A);
    top: calc(100% - 12px);
    left: 2px;
}
.villa-inner-visual ul li:nth-child(2) .label-bottom-lines{
    height: 98px;
}
.villa-inner-visual ul li:nth-child(3) .label-bottom-lines{
    height: 225px;
}
.villa-inner-visual ul li:nth-child(2) .label-bottom-lines::before,
.villa-inner-visual ul li:nth-child(2) .label-bottom-lines::after,
.bottom-list-points li:first-child .label-bottom-lines::before,
.bottom-list-points li:first-child .label-bottom-lines::after{
    background-color: var(--dark-yellow-color);
}
.villa-inner-visual ul li:nth-child(2) .label-bottom-lines::after,
.bottom-list-points li:first-child .label-bottom-lines::after{
    box-shadow: 0px 0px 23.233078002929688px 0px #E7C16D;
}
.villa-inner-visual ul li:nth-child(2) .label-bottom-lines::after{
    filter: drop-shadow(0px 0px 23.233078002929688px var(--dark-yellow-color));
}
.bottom-list-points .label-bottom-lines::after{
    top: 0;
}
.bottom-list-points li:first-child .label-bottom-lines{
    height: 108px;
}
.villa-inner-visual .bottom-list-points li:last-child .label-bottom-lines{
    height: 118px;
}
.villa-inner-visual .bottom-list-points li:last-child .label-bottom-lines::before,
.villa-inner-visual .bottom-list-points li:last-child .label-bottom-lines::after{
    background-color: var(--green-wet);
}
.villa-inner-visual .bottom-list-points li:first-child {
    margin-top: 0;
    margin-bottom: 12px;
}
.partners-form-contact{
    padding: 90px 0 50px;
}
.partner-des, .comfort-body{
    margin-top: 20px;
}
.work-process {
    background-color: var(--black-color);
    padding: 60px 0;
}
.work-process .small-heading::before {
    background-color: var(--dark-yellow-color);
}
.work-process .small-heading span {
    color: var(--white-color);
}
.work-process .heading-type-two h2 {
    color: var(--white-color);
}
.work-step-card-body h3 {
    color: var(--white-color);
}
.work-step-card-body h3 span {
    color: var(--dark-yellow-color);
}
.work-step-card-body p {
    color: #ffffff92;
}
.work-step::before {
    content: "";
    position: absolute;
    width: 1px;
    height: calc(100% - 110px);
    border: 1px dashed var(--dark-yellow-color);
    left: 0;
    right: 0;
    margin: auto;
}
.work-step-card .work-step-card-icon {
    width: 70px;
    height: 70px;
    background: rgb(100 90 68);
    border: 6px solid var(--black-color);
    outline: 3px solid var(--dark-yellow-color);
}
.work-step-card .work-step-card-icon {
    font-size: 24px;
    color: var(--dark-yellow-color);
    font-family: var(--primary-font);
}
.work-step .work-step-card:nth-child(even) {
    margin-left: auto;
}
.work-step .work-step-card:nth-child(even) .work-step-card-icon {
    left: -130px;
    right: unset;
}
.work-step {
    max-width: 938px;
    margin-top: 60px;
}
.work-step-card{
    max-width: 375px;
}
.work-step li{
    display: flex;
    position: relative;
}
.work-step li:nth-child(even){
    justify-content: end;
}

/* Social Infuencer */
.find-us{
    padding: 90px 0;
}
.social-links li{
    width: calc(100%/4);
}
.social-links li:not(:last-child){
    padding-right: 50px;
}
.social-links li:not(:last-child) a{
    border-right: 1px solid var(--dark-yellow-color);
}
.media-username h5{
    font-family: var(--dunbar-tall);
    color: var(--dark-yellow-color);
    margin: 0 0 7px;
}
.media-username span{
    color: var(--bs-body-color);
}
.social-media-icon {
	width: 68px;
	height: 68px;
	font-size: 30px;
    color: #D2AE5F;
    border: 1px solid #D2AE5F;
}
.social-links a:hover .social-media-icon{
    background-color: var(--dark-yellow-color);
    color: var(--bs-white);
}
.media-username{
    width: calc(100% - 68px);
    padding-left: 20px;
}
.social-links li:nth-child(even) .social-media-icon{
    border-color: var(--secondary-color);
    color: var(--secondary-color);
}
.social-links li:nth-child(even) a:hover .social-media-icon{
    background-color: var(--secondary-color);
    color: var(--bs-white);
}
.social-links li:nth-child(even) .media-username h5{
    color: var(--secondary-color);
}
.founder-message .position-name{
    color: var(--black-shade-1);
}
.Toastify .Toastify__toast-container{
    z-index: 99999999999;
}
.rentalpool .small-heading hr,
.customer-relation-panel .small-heading hr{
    width: 75px;
}
.welcome-our-curiosity .textarea-field{
    height: 150px;
}
.curiosity-form .form-input {
    margin-bottom: 6px;
    position: relative;
    display: inline-block;
    width: 100%;
}
.curiosity-form .form-error {
    position: absolute;
    font-size: 12px;
    margin: 0;
    bottom: -25px;
}
.main-slider .slider-content{
    min-height: 800px;
}
.founder-message .position-name::before{
    content: "";
    display: inline-block;
    width: 48px;
    height: 1px;
    background-color: var(--black-shade-1);
    margin-right: 13px;
}
.founder-message  .position-right-alt::before{
    order: 2;
    margin-left: 13px;
}
.founder-img img{
    border-radius: 5px;
}
.sustainability-card-icon img{
    margin: auto;
}
.responsible-card-text p {
    min-height: 105px;
  }